import Alert from '@mui/material/Alert';
import { useEffect } from 'react';
import useSites from '../../../datastore/useSites';
import useSelectedSite from '../../../hooks/useSelectedSite';
import { OrderingAvailabilityStatus } from '../../../common/enums';
import { onlineOrderingUnavailable, orderNowForPickupTomorrow } from '../../../common/constants';


export default function OrderLockoutBanner() {
    const { fetchSites, getSiteOrderingAvailabilityStatus } = useSites()
    const { selectedSiteId } = useSelectedSite((state) => ({ selectedSiteId: state.selectedSiteId }))

    useEffect(() => { fetchSites() }, [])

    const orderingStatus = getSiteOrderingAvailabilityStatus(selectedSiteId)

    switch (orderingStatus) {
        case OrderingAvailabilityStatus.Unavailable: {
            return (
                <Alert severity="error">
                    {onlineOrderingUnavailable}
                </Alert>
            )
        }
        case OrderingAvailabilityStatus.Tomorrow: {
            return (
                <Alert severity="error">
                    {orderNowForPickupTomorrow}
                </Alert>
            )
        }
        default: {
            return null
        }
    }
}