import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

type ConfirmationDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
  confirmButtonText?: string;
};

export default function ConfirmModal({
  onClose,
  onConfirm,
  title,
  content,
  confirmButtonText = 'Confirm',
}: ConfirmationDialogProps) {
  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ paddingBottom: '0.5em' }}
        >
          {title}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body2">{content}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
