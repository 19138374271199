import { ReportEndpoint } from '../common/endpoints';
import {
  ReportDataPoint,
  ServerResponse
} from '../common/types';
import { postRequest } from './requests';

export async function componentConsumption(startDate: Date, timePeriod: number, comparisonPeriods: number) {
  const result: ServerResponse<ReportDataPoint[]> = await postRequest(
    `${ReportEndpoint}/component-consumption`,
    { startDate, timePeriod, comparisonPeriods }
  );
  return result;
}

export async function totalSales(startDate: Date, timePeriod: number, comparisonPeriods: number) {
  const result: ServerResponse<ReportDataPoint[]> = await postRequest(
    `${ReportEndpoint}/total-sales`,
    { startDate, timePeriod, comparisonPeriods }
  );
  return result;
}

export async function productSales(startDate: Date, timePeriod: number, comparisonPeriods: number) {
  const result: ServerResponse<ReportDataPoint[]> =
    await postRequest(`${ReportEndpoint}/products-sold`, { startDate, timePeriod, comparisonPeriods });
  return result;
}

export async function averageSpend(startDate: Date, timePeriod: number, comparisonPeriods: number) {
  const result: ServerResponse<ReportDataPoint[]> =
    await postRequest(`${ReportEndpoint}/average-spend`, { startDate, timePeriod, comparisonPeriods });
  return result;
}

export async function revenueCostProfit(startDate: Date, timePeriod: number, comparisonPeriods: number) {
  const result: ServerResponse<ReportDataPoint[]> =
    await postRequest(`${ReportEndpoint}/revenue-cost-profit`, { startDate, timePeriod, comparisonPeriods });
  return result;
}

export default {
  componentConsumption,
  totalSales,
  revenueCostProfit,
  productSales,
  averageSpend
};
