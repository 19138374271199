import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React, { useRef, useState } from 'react';
import staffApi from '../../../api/staff';
import { hasAdminRole } from '../../../common/roleFunctions';
import { isStaff } from '../../../common/typeAssertionFunctions';
import { StaffAccount } from '../../../common/types';
import useStaff from '../../../datastore/useStaff';
import useAlert from '../../../hooks/useAlert';
import useAPI from '../../../hooks/useAPI';
import { AdminHeader } from '../components/AdminHeader';
import AccountManagementFilter from './components/AccountManagementFilter';
import AccountManagementTable from './components/AccountManagementTable';
import EditAccountModal from './components/EditAccountModal';
import NewAccountModal from './components/NewAccountModal';

const AccountsPerPage = 200;

export default function AccountManagementPage() {
  const { currentUser } = useAPI();
  const { showAlert } = useAlert();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [filterText, setFilterText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [editAccount, setEditAccount] = useState<StaffAccount | null>(null);
  const [newAccount, setNewAccount] = useState<Partial<StaffAccount> | null>(
    null,
  );

  const {
    showDisabled: showDisabledAccounts,
    setShowDisabled: setShowDisabledAccounts,
    staff: accountsData,
    loading: isLoading,
    error,
    fetchStaff: refetchAccounts,
  } = useStaff();

  if (error) {
    showAlert('Error retrieving accounts, refresh and try again.', 'error');
    return null;
  }

  if (!hasAdminRole(currentUser) || !isStaff(currentUser)) return null;

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }

  async function statusToggle(account: StaffAccount) {
    const updatedAccount = { ...account, isActive: !account.isActive };
    const result = await staffApi.updateStaff(updatedAccount);
    if (result.status !== 200) {
      showAlert(
        'Error updating account status, refresh and try again.',
        'error',
      );
      return;
    }
    refetchAccounts();
    showAlert('Account updated successfully', 'success');
  }

  async function handleSaveEditAccount(editedAccount: StaffAccount) {
    const result = await staffApi.updateStaff(editedAccount);
    if (result.status !== 200) {
      showAlert('Error updating account, refresh and try again.', 'error');
      return;
    }
    refetchAccounts();
    showAlert('Account updated successfully', 'success');
  }

  async function handleSaveNewAccount(newAccount: StaffAccount) {
    const result = await staffApi.createStaff(newAccount);
    if (result.status !== 201 || !result.data) {
      showAlert('Error creating account, refresh and try again.', 'error');
      return;
    }
    refetchAccounts();
    showAlert('Account created successfully', 'success');
  }

  const activeAccounts = showDisabledAccounts
    ? accountsData
    : accountsData?.filter((account) => account.isActive);

  const filteredAccounts =
    activeAccounts?.filter((account) =>
      filterText
        ? account.email.toLowerCase().includes(filterText.toLowerCase())
        : true,
    ) || [];

  const totalNumberOfPages = Math.ceil(
    filteredAccounts.length / AccountsPerPage,
  );

  const startIndex = (currentPage - 1) * AccountsPerPage;
  const endIndex = Math.min(
    startIndex + AccountsPerPage,
    filteredAccounts.length,
  );
  const paginatedAccounts = filteredAccounts.slice(startIndex, endIndex);

  const defaultAccount: Partial<StaffAccount> = {
    email: '',
    isActive: true,
  };

  return (
    <Stack
      sx={{
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <AdminHeader
        title="Accounts"
        description="View & Edit Staff and Admin Accounts"
        icon={<PersonIcon fontSize="large" sx={{ color: 'white' }} />}
        buttonText="New Account"
        buttonAction={() => setNewAccount(defaultAccount)}
      />

      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <AccountManagementFilter
          filterText={filterText}
          setFilterText={setFilterText}
          showDisabledAccounts={showDisabledAccounts}
          setShowDisabledAccounts={setShowDisabledAccounts}
        />

        <Box
          ref={tableContainerRef}
          sx={{
            flex: 1,
            overflowY: 'auto',
            minHeight: 0,
          }}
        >
          <AccountManagementTable
            isLoading={isLoading}
            paginatedAccounts={paginatedAccounts}
            statusToggle={statusToggle}
            editAccount={(account) => setEditAccount(account)}
          />
        </Box>

        <Box
          sx={{
            borderTop: 1,
            borderColor: 'divider',
            py: 2,
            backgroundColor: 'background.paper',
          }}
        >
          <Stack spacing={2} direction="row" justifyContent="center">
            <Pagination
              count={totalNumberOfPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="medium"
            />
          </Stack>
        </Box>
      </Stack>

      {editAccount && (
        <EditAccountModal
          open={!!editAccount}
          onClose={() => setEditAccount(null)}
          onSave={handleSaveEditAccount}
          account={editAccount}
        />
      )}
      {newAccount && (
        <NewAccountModal
          open={!!newAccount}
          onClose={() => setNewAccount(null)}
          onSave={handleSaveNewAccount}
        />
      )}
    </Stack>
  );
}
