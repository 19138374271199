/* eslint-disable @typescript-eslint/no-explicit-any */
import { add } from 'date-fns/add';
import { endOfDay } from 'date-fns/endOfDay';
import { endOfMonth } from 'date-fns/endOfMonth';
import { endOfWeek } from 'date-fns/endOfWeek';
import { endOfYear } from 'date-fns/endOfYear';
import { format } from 'date-fns/format';
import { getDate } from 'date-fns/getDate';
import { getDay } from 'date-fns/getDay';
import { getHours } from 'date-fns/getHours';
import { getMonth } from 'date-fns/getMonth';
import { startOfDay } from 'date-fns/startOfDay';
import { startOfMonth } from 'date-fns/startOfMonth';
import { startOfWeek } from 'date-fns/startOfWeek';
import { startOfYear } from 'date-fns/startOfYear';
import Decimal from 'decimal.js';
import { awstFromUtc, awstToUtc, endOfWeekWA, formatDate, formatDateForReport, startOfWeekWA } from '../../../../common/timeFunctions';
import { ReportDataPoint } from '../../../../common/types';

export const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'] as const;
export const getColor = (index: number): string => {
  return colors[index % colors.length];
}

export const parseCustomDate = (dateString: string | number) => {
  if (typeof (dateString) === 'string' && dateString.indexOf('T') > -1) {
    return new Date(dateString)
  }
  let dateChunk = ''
  if (typeof (dateString) === 'string') {
    dateChunk = dateString.split('_')[0]
  } else {
    dateChunk = formatDate(new Date(dateString))
  }
  // Mapping of month abbreviations
  const months: Record<string, number> = {
    'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
    'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
  };

  // Try different parsing patterns
  const parsePatterns = [
    // Daily/Weekly: "Mon, 9 Dec 2024" or "Mon, 9 Dec 2024 8:00 AM"
    () => {
      const match = dateChunk.match(/(\w+), (\d+) (\w+) (\d+)(?:\s+(\d+):(\d+)\s*(AM|PM))?/);
      if (match) {
        const [, , day, monthStr, year, hours, minutes, ampm] = match;

        let hour = hours ? parseInt(hours) : 0;
        const minute = minutes ? parseInt(minutes) : 0;

        if (ampm) {
          if (ampm === 'PM' && hour !== 12) hour += 12;
          if (ampm === 'AM' && hour === 12) hour = 0;
        }

        return new Date(
          parseInt(year),
          months[monthStr],
          parseInt(day),
          hour,
          minute
        );
      }
      return null;
    },
    // Monthly: "Dec 2024"
    () => {
      const match = dateChunk.match(/(\w+)\s+(\d+)/);
      if (match) {
        const [, monthStr, year] = match;
        return new Date(parseInt(year), months[monthStr], 1);
      }
      return null;
    }
  ];

  // Try each parsing pattern
  for (const parse of parsePatterns) {
    const parsedDate = parse();
    if (parsedDate) return parsedDate;
  }

  throw new Error(`Unable to parse date: ${dateString}`);
}

export const formatTickLabel = (dataKey: string, timePeriod: number) => {
  if (!dataKey) return '';

  try {
    const date = parseCustomDate(dataKey);

    switch (timePeriod) {
      case 0: // Daily
        return format(date, 'h a')

      case 1: // Weekly
        return format(date, 'EEE do')

      case 2: {// Monthly
        const start = startOfWeek(date, { weekStartsOn: 1 })
        const end = endOfWeek(date, { weekStartsOn: 1 })
        return `${format(start, 'do MMM')} - ${format(end, 'do MMM')}`
      }
      case 3: // Yearly
        return format(date, 'MMM')

      default:
        return dataKey;
    }
  } catch (error) {
    console.error('Error parsing date:', error);
    return dataKey;
  }
}

const formatTickSectionLabel = (dataKey: string, timePeriod: number) => {
  if (!dataKey) return '';

  try {
    const date = parseCustomDate(dataKey);

    switch (timePeriod) {
      case 0: {// Daily
        if (getHours(date) >= 5) return ''
        return format(date, 'EEE, d MMM yyyy')
      }

      case 1: {// Weekly
        if (getDay(date) !== 1) return ''
        const start = startOfWeek(awstFromUtc(date), { weekStartsOn: 1 })
        const end = endOfWeek(awstFromUtc(date), { weekStartsOn: 1 })
        return `${format(start, 'do MMM yyyy')} - ${format(end, 'do MMM yyyy')}`
      }
      case 2: // Monthly
        if (format(date, 'eee') !== 'Mon') return ''
        return format(date, 'MMM yyyy')

      case 3: // Yearly
        if (getDate(date) !== 1 || getMonth(date) !== 0) return ''
        return format(date, 'yyyy')

      default:
        return dataKey;
    }
  } catch (error) {
    console.error('Error parsing date:', error);
    return dataKey;
  }
}

export const barLabel = (val: string, timePeriod: number): string => {
  return formatTickLabel(val, timePeriod)
}

export const renderPeriodTick = (tickProps: any, timePeriod: number) => {
  const { x, y, payload } = tickProps;
  const { value, offset } = payload;
  const date = parseCustomDate(value);

  const drawTick: boolean = timePeriod === 0 &&
    date.getHours() === 0 ||
    timePeriod === 1 && date.getTime() === startOfWeekWA(date).getTime() ||
    timePeriod === 2 && format(date, 'eee') === 'Mon' && date.getDate() <= 7 ||
    timePeriod === 3 && date.getDate() === 1 && date.getMonth() === 0

  if (drawTick) {
    const pathX = Math.floor(x - offset) + 0.5;

    return (
      <>
        <path d={`M${pathX},${y - 4}v${-35}`} stroke="black" />
        <text x={pathX + 110} y={y + 10} textAnchor="middle">{formatTickSectionLabel(value, timePeriod)}</text>;
      </>
    )
  }
  return <></>;
};

export const padDataPoints = (
  originalData: ReportDataPoint[],
  timePeriod: number,
  startDate: Date,
  comparisonPeriod: number
): ReportDataPoint[] => {
  // Convert start date to AWST
  const awstStartDate = awstFromUtc(startDate);

  let paddedStartDate = new Date(awstStartDate);
  let paddedEndDate = new Date(awstStartDate);

  switch (timePeriod) {
    case 0: // Hourly data
      paddedStartDate = startOfDay(paddedStartDate);
      paddedStartDate = add(paddedStartDate, { days: -comparisonPeriod });
      paddedEndDate = endOfDay(paddedEndDate);
      break
    case 1: // Daily (weekly) data
      paddedStartDate = startOfWeekWA(awstStartDate); // Monday
      paddedStartDate = add(paddedStartDate, { days: -comparisonPeriod * 7 });
      paddedEndDate = endOfWeekWA(awstStartDate);
      break
    case 2: // Weekly (monthly) data
      paddedStartDate = startOfMonth(awstStartDate);
      paddedStartDate = add(paddedStartDate, { months: -comparisonPeriod })
      paddedEndDate = endOfMonth(awstStartDate);
      break
    case 3: // Monthly (yearly) data
      paddedStartDate = startOfYear(awstStartDate);
      paddedStartDate = add(paddedStartDate, { years: -comparisonPeriod })
      paddedEndDate = endOfYear(awstStartDate);
      break
    default:
      throw new Error('Invalid timePeriod provided')
  }
  const out = padData(originalData, paddedStartDate, paddedEndDate, timePeriod);
  return out
}

function padData(
  originalData: ReportDataPoint[],
  startDate: Date,
  endDate: Date,
  timePeriod: number
): ReportDataPoint[] {
  const paddedData: ReportDataPoint[] = [];
  let currentDate = new Date(startDate);
  let key: 'hours' | 'days' | 'weeks' | 'months' = 'hours'
  switch (timePeriod) {
    case 1:
      key = 'days';
      break;
    case 2:
      key = 'weeks';
      break;
    case 3:
      key = 'months';
      break;
    default:
      key = 'hours';
      break;
  }
  while (currentDate <= endDate) {
    // Convert current date back to UTC for comparison
    const currentUTCDate = awstToUtc(currentDate);
    const placeHolder: ReportDataPoint = {
      timestamp: formatDateForReport(awstFromUtc(currentUTCDate), timePeriod),
    }
    paddedData.push(placeHolder)

    currentDate = add(currentDate, { [key]: 1 });
  }

  return paddedData.map(m => originalData.find(f => formatDateForReport(parseCustomDate(f.timestamp), timePeriod) === m.timestamp) ?? m);
}


export function getTotalsRow(groupedData: ReportDataPoint[], keys: string[], decimalPlaces: number, withUnits = false): ReportDataPoint {
  const totals: ReportDataPoint = { timestamp: 'totals' }
  for (const key of keys) {
    totals[key] = groupedData.map(m => m?.[key] ?? 0).reduce((sum, item) => new Decimal(item ?? 0).plus(sum), new Decimal(0)).toFixed(decimalPlaces)
    if (withUnits) {
      const unitsKey = `${key}_units`
      const units = groupedData.map(m => m?.[unitsKey]).filter(Boolean)?.[0] ?? ''
      totals[unitsKey] = units
    }
  }
  return totals
}