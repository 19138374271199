import { create } from 'zustand';
import componentTypesApi from '../api/componentTypes';
import { TpgeComponentType } from '../common/types';

type ComponentTypesStore = {
  hasLoaded: boolean;
  componentTypes: TpgeComponentType[];
  loading: boolean;
  error: unknown | null;
  milkType: TpgeComponentType | null;
  beansType: TpgeComponentType | null;
  shotsType: TpgeComponentType | null;
  sugarsType: TpgeComponentType | null;
  syrupsType: TpgeComponentType | null;
  fetchComponentTypes: (inclueDeleted?: boolean) => void;
};

const useComponentTypes = create<ComponentTypesStore>((set, get) => ({
  hasLoaded: false,
  componentTypes: [],
  loading: false,
  error: null,
  beansType: null,
  milkType: null,
  shotsType: null,
  sugarsType: null,
  syrupsType: null,
  fetchComponentTypes: async (includeDeleted = false) => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await componentTypesApi.getComponentTypes(includeDeleted);
      const data = result?.data ?? [];
      const milkType = data.find((pvt) => pvt.name === 'Milk') ?? null;
      const beansType = data.find((pvt) => pvt.name === 'Beans') ?? null;
      const shotsType = data.find((pvt) => pvt.name === 'Extra Shot') ?? null;
      const sugarsType = data.find((pvt) => pvt.name === 'Sugars') ?? null;
      const syrupsType = data.find((pvt) => pvt.name === 'Syrups') ?? null;
      set({
        componentTypes: data,
        loading: false,
        error: null,
        beansType,
        milkType,
        shotsType,
        sugarsType,
        syrupsType
      });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
}));

export default useComponentTypes;

if (!useComponentTypes.getState().hasLoaded) {
  useComponentTypes.getState().fetchComponentTypes();
}
