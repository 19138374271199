import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { PropsWithChildren } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ReportTimePeriods, ReportView } from '../../../../common/enums.tsx';

interface ReportHeaderProps {
  date: Date | null;
  setDate: (date: Date) => void;
  timePeriod: ReportTimePeriods;
  setTimePeriod: (timePeriod: ReportTimePeriods) => void;
  comparisonPeriods: number;
  setComparisonPeriods: (value: string | number) => void;
  view: ReportView.Chart | ReportView.Table;
  handleViewChange: (
    event: React.MouseEvent<HTMLElement>,
    newView: ReportView.Chart | ReportView.Table | null,
  ) => void;
  clear: () => void;
}

export default function ReportHeader({
  date,
  setDate,
  timePeriod,
  setTimePeriod,
  comparisonPeriods,
  setComparisonPeriods,
  view,
  handleViewChange,
  clear,
  children,
}: PropsWithChildren<ReportHeaderProps>) {

  const setDateHandleNull = (date: Date | null) => {
    setDate(date ?? new Date())
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        paddingTop: 3,
        marginBottom: view === ReportView.Chart ? '1.25em' : '4em',
        width: '100%',
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        flex={1}
        sx={{ paddingLeft: 3 }}
      >
        <DatePicker
          label="Date"
          value={date}
          onChange={setDateHandleNull}
          format="dd/MM/yyyy"
          slotProps={{
            textField: {
              size: 'small',
              sx: {
                '& .MuiInputBase-root': {
                  height: 40,
                },
              },
            },
          }}
        />
        <TextField
          select
          value={timePeriod}
          label="View"
          onChange={(e) => setTimePeriod(+e.target.value as ReportTimePeriods)}
          size="small"
          sx={{ width: '120px' }}
        >
          <MenuItem value={ReportTimePeriods.Daily}>Daily</MenuItem>
          <MenuItem value={ReportTimePeriods.Weekly}>Weekly</MenuItem>
          <MenuItem value={ReportTimePeriods.Monthly}>Monthly</MenuItem>
          <MenuItem value={ReportTimePeriods.Yearly}>Yearly</MenuItem>
        </TextField>
        <TextField
          fullWidth
          label="Comparison Periods"
          size="small"
          sx={{ width: '170px' }}
          slotProps={{
            htmlInput: {
              style: { textAlign: 'center' },
            },
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => setComparisonPeriods(comparisonPeriods - 1)}
                    disabled={comparisonPeriods === 0}
                  >
                    <RemoveIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setComparisonPeriods(comparisonPeriods + 1)}
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
          value={comparisonPeriods}
        />
        {children}
        <Button
          variant="contained"
          onClick={clear}
          size="small"
          sx={{ height: 40 }}
        >
          Reset
        </Button>
      </Stack>

      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        aria-label="view selector"
        size="small"
        sx={{ paddingRight: 5 }}
      >
        <ToggleButton value={ReportView.Chart} aria-label="chart view">
          <BarChartIcon />
        </ToggleButton>
        <ToggleButton value={ReportView.Table} aria-label="table view">
          <TableChartIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack >
  );
}
