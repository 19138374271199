import ErrorIcon from '@mui/icons-material/Error';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { OrderItemStatus, ProductCategoryType, Stations } from '../../../common/enums';
import { unfinishedOrdersForStation } from '../../../common/orderFunctions';
import { isAdmin, isStaff } from '../../../common/typeAssertionFunctions';
import InfoCard from '../../../components/card/InfoCard';
import useOrders from '../../../datastore/useOrders';
import useProducts from '../../../datastore/useProducts';
import useStations from '../../../datastore/useStations';
import useAPI from '../../../hooks/useAPI';
import { sortStationOrders } from '../functions/stationFunctions';
import { StaffOrder } from '../../../common/types';
import ColdDrinksStation from './components/ColdDrinksStation';
import FoodStation from './components/FoodStation';
import OpposingStation from './components/OpposingStation';
import PrimaryStation from './components/PrimaryStation';
import PartiallyCompleteOrders from './components/PartiallyCompleteOrders';

const sortedUnfinishedOrdersForStation = (orders: StaffOrder[], stationId: number, categories: ProductCategoryType[]): StaffOrder[] => {
  const unfinishedOrders = unfinishedOrdersForStation(orders, stationId);
  return sortStationOrders(unfinishedOrders, categories).map((o, i) => ({ ...o, queueOrder: i + 1 })).sort((a, b) => a.queueOrder - b.queueOrder);
}

export default function StaffHomePage() {
  const { currentUser } = useAPI();
  const theme = useTheme();
  const { orders = [], startPolling, stopPolling } = useOrders();
  const { products = [], loading: productsLoading } = useProducts();
  const { stations = [] } = useStations();

  useEffect(() => {
    startPolling({ initialInterval: 5000, maxInterval: 15000 });
    return () => {
      stopPolling();
    };
  }, []);

  if (!isStaff(currentUser)) return null;

  if (!products.length && !productsLoading) {
    if (isAdmin(currentUser)) {
      return (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.common.white,
            height: '100vh',
          }}
        >
          <Link to="/admin/products" style={{ textDecoration: 'none' }}>
            <Stack sx={{ flexDirection: 'column', cursor: 'pointer' }}>
              <InfoCard
                Icon={ErrorIcon}
                title="No Products"
                subtitle="Start adding Products"
              />
            </Stack>
          </Link>

        </Grid>
      )
    } else {
      <Stack sx={{ flexDirection: 'column', cursor: 'pointer' }}>
        <InfoCard
          Icon={ErrorIcon}
          title="No Products"
          subtitle="Someone needs to add Products!"
        />
      </Stack>

    }
  }

  const mappedOrders = (orders || []).map((o) => ({
    ...o,
    requestedPickupTime: o.requestedPickupTime
      ? new Date(o.requestedPickupTime)
      : null,
    startTime: o.startTime ? new Date(o.startTime) : null,
    finishTime: o.finishTime ? new Date(o.finishTime) : null,
  }));

  const stationCategories: ProductCategoryType[] = []
  switch (currentUser.stationName) {
    case Stations.StationOne:
      stationCategories.push(ProductCategoryType.HotCoffee, ProductCategoryType.Tea);
      break;
    case Stations.StationTwo:
      stationCategories.push(ProductCategoryType.HotCoffee, ProductCategoryType.Tea);
      break;
    case Stations.ColdDrinks:
      stationCategories.push(ProductCategoryType.ColdDrinks);
      break;
    case Stations.Food:
      stationCategories.push(ProductCategoryType.Food);
      break;
  }

  const sortedUnfinishedOrders = sortedUnfinishedOrdersForStation(mappedOrders, currentUser.stationId, stationCategories)
  const partiallyFinishedOrders = mappedOrders.filter(order => {
    const someUnfinished = order.orderItems.some(item => item.status !== OrderItemStatus.Finished)
    const itemsForThisStation = order.orderItems.filter(item => item.stationId === currentUser.stationId)
    const thisStationFinished = itemsForThisStation.every(item => item.status === OrderItemStatus.Finished)
    return someUnfinished && itemsForThisStation.length && thisStationFinished
  })

  let opposingStationOrders: StaffOrder[] = []
  const opposingStationName = currentUser.stationName === Stations.StationOne ? Stations.StationTwo : Stations.StationOne
  const opposingStationId = stations?.find((station) => station.name === opposingStationName)?.id

  if (opposingStationId && (currentUser.stationName === Stations.StationOne || currentUser.stationName === Stations.StationTwo)) {
    opposingStationOrders = sortedUnfinishedOrdersForStation(mappedOrders, opposingStationId, stationCategories);
  }

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.common.white,
        height: '100vh',
      }}
    >

      {currentUser.stationName === Stations.StationOne ||
        currentUser.stationName === Stations.StationTwo ? (
        <>
          <PrimaryStation primaryOrders={sortedUnfinishedOrders}>
            <PartiallyCompleteOrders outstandingOrders={partiallyFinishedOrders} />
          </PrimaryStation>
          <OpposingStation
            opposingOrders={opposingStationOrders}
            user={currentUser}
            stations={stations ?? []}
          />
        </>
      ) : currentUser.stationName === Stations.ColdDrinks ? (
        <ColdDrinksStation orders={sortedUnfinishedOrders}>
          <PartiallyCompleteOrders outstandingOrders={partiallyFinishedOrders} />
        </ColdDrinksStation>
      ) : currentUser.stationName === Stations.Food ? (
        <FoodStation orders={sortedUnfinishedOrders}>
          <PartiallyCompleteOrders outstandingOrders={partiallyFinishedOrders} />
        </FoodStation>
      ) : (
        <Link to="/staff/settings" style={{ textDecoration: 'none' }}>
          <Stack sx={{ flexDirection: 'column', cursor: 'pointer' }}>
            <InfoCard
              Icon={ErrorIcon}
              title="No Station Selected"
              subtitle="Click here to go to settings and select a station."
            />
          </Stack>
        </Link>
      )}
    </Grid>
  );
}
