/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import componentTypesApi from '../../../../api/componentTypes.tsx';
import { TpgeComponentType } from '../../../../common/types.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import ConfirmModal from '../../../../components/modal/ConfirmModal.tsx';
import useComponentTypes from '../../../../datastore/useComponentTypes.tsx';
import useAlert from '../../../../hooks/useAlert.tsx';
import useLoadingModal from '../../../../hooks/useLoadingModal.tsx';

interface ComponentTypesTableProps {
  isLoading: boolean;
  paginatedData: TpgeComponentType[];
  showDisabled: boolean;
  onEditClicked: (component: TpgeComponentType) => void;
  statusToggle: (component: TpgeComponentType) => void;
}

export function ComponentTypesTable({
  isLoading,
  paginatedData,
  showDisabled,
  onEditClicked,
  statusToggle,
}: ComponentTypesTableProps) {
  const navigate = useNavigate()
  const [archiveId, setArchiveId] = useState<number | null>(null);
  const { setLoading } = useLoadingModal()
  const { fetchComponentTypes } = useComponentTypes()
  const { showAlert } = useAlert()


  function handleArchiveClick(id: number) {
    setArchiveId(id);
  }

  function goToArchive(id: number) {
    navigate(`/admin/componenttypes/${id}/archive`)
  }

  async function archive() {
    if (!archiveId) {
      return
    }
    try {
      setLoading(true)
      await componentTypesApi.archive(archiveId);
      fetchComponentTypes();
      setArchiveId(null);
      showAlert('ComponentType archived successfully', 'success')
    } catch (err: any) {
      console.error(err)
      showAlert(err.data ?? err.message ?? 'Error archiving ComponentType', 'error')
    } finally {
      setLoading(false)
    }
  }

  const itemToArchive = paginatedData.find((f) => f.id === archiveId);

  return (
    <Table sx={{ maxWidth: '100%', overflowX: 'auto' }} size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: '50%' }}>Name</TableCell>
          <TableCell sx={{ width: '50%' }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <LoadingSpinner isLoading={isLoading} isTable colSpan={2}>
          {paginatedData.map((componentType) => {
            if (!componentType.isActive && !showDisabled) return null;
            return (
              <TableRow
                key={componentType.id}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    cursor: 'pointer',
                  },
                }}
              >
                <TableCell sx={{ width: '50%' }}>
                  {componentType.name}
                </TableCell>
                <TableCell sx={{ width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                  {
                    componentType.isLockedForUserInterface ? (
                      <Typography variant="caption">Locked for core system functionality</Typography>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => onEditClicked(componentType)}
                          sx={{ marginRight: 2 }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => statusToggle(componentType)}
                          color={componentType.isActive ? 'error' : 'primary'}
                          sx={{ marginRight: 2 }}
                        >
                          {componentType.isActive ? 'Disable' : 'Enable'}
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleArchiveClick(componentType.id)}
                          color={'warning'}
                        >
                          Archive
                        </Button>
                      </>
                    )
                  }
                </TableCell>
              </TableRow>
            );
          })}
        </LoadingSpinner>
      </TableBody>
      {
        itemToArchive && itemToArchive.activeComponentCount > 0 && (
          <ConfirmModal
            title={`Archive ${itemToArchive.name}`}
            content={`This component type has ${itemToArchive.activeComponentCount} active components. You will need to review them before archiving`}
            onClose={() => setArchiveId(null)}
            onConfirm={() => goToArchive(itemToArchive.id)}
            confirmButtonText='Review'
          />
        )
      }
      {
        itemToArchive && itemToArchive.activeComponentCount === 0 && (
          <ConfirmModal
            title={`Archive ${itemToArchive.name}`}
            content={`Are you sure you want to PERMANENTLY ARCHIVE ${itemToArchive.name}?`}
            onClose={() => setArchiveId(null)}
            onConfirm={archive}
          />
        )
      }
    </Table>
  );
}
