import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { ProductCategoryType, Stations } from '../../../common/enums';
import { isStaff } from '../../../common/typeAssertionFunctions';
import { StaffOrder } from '../../../common/types';
import useOrders from '../../../datastore/useOrders';
import useStations from '../../../datastore/useStations';
import useAPI from '../../../hooks/useAPI';
import { unfinishedOrdersForStation } from '../../../common/orderFunctions';
import { sortStationOrders } from '../functions/stationFunctions';
import Station from './components/Station';


const hotDrinkTypes = [ProductCategoryType.HotCoffee, ProductCategoryType.Tea];

const sortedUnfinishedOrdersForStation = (orders: StaffOrder[], stationId: number, categories: ProductCategoryType[]): StaffOrder[] => {
  const unfinishedOrders = unfinishedOrdersForStation(orders, stationId);
  return sortStationOrders(unfinishedOrders, categories).map((o, i) => ({ ...o, queueOrder: i + 1 })).sort((a, b) => a.queueOrder - b.queueOrder);
}

export default function WalkUpPage() {
  const { currentUser } = useAPI();
  const { orders = [], startPolling, stopPolling } = useOrders();
  const { stations = [] } = useStations();
  const theme = useTheme();

  useEffect(() => {
    startPolling({initialInterval: 5000, maxInterval: 15000});
    return () => {
      stopPolling();
    };
  }, []);

  const mappedOrders: StaffOrder[] = (orders || []).map((o) => ({
    ...o,
    requestedPickupTime: o.requestedPickupTime
      ? new Date(o.requestedPickupTime)
      : null,
    startTime: o.startTime ? new Date(o.startTime) : null,
    finishTime: o.finishTime ? new Date(o.finishTime) : null,
  }));

  const stationOne = stations?.find(
    (station) => station.name === Stations.StationOne,
  );
  const stationTwo = stations?.find(
    (station) => station.name === Stations.StationTwo,
  );

  if (!stationOne || !stationTwo || !isStaff(currentUser)) return;

  const stationOneOrders = sortedUnfinishedOrdersForStation(mappedOrders, stationOne.id, hotDrinkTypes) ?? [];
  const stationTwoOrders = sortedUnfinishedOrdersForStation(mappedOrders, stationTwo.id, hotDrinkTypes) ?? [];


  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.common.white,
        height: '100vh',
      }}
    >
      <Station
        orders={stationOneOrders}
        stationName={Stations.StationOne}
        stationId={stationOne.id}
        opposingStationId={stationTwo.id}
      />
      <Station
        orders={stationTwoOrders}
        stationName={Stations.StationTwo}
        stationId={stationTwo.id}
        opposingStationId={stationOne.id}
      />
    </Grid>
  );
}
