
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Decimal from 'decimal.js';
import reportsApi from '../../../../api/report.tsx';
import { ReportView } from '../../../../common/enums.tsx';
import { formatDateForReport } from '../../../../common/timeFunctions.tsx';
import { ReportDataPoint } from '../../../../common/types.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import useRequest from '../../../../hooks/useRequest.tsx';
import { formatCurrency } from '../../../../common/moneyFunctions.tsx';
import { padDataPoints, parseCustomDate } from './chartFunctions.tsx';
import ReportHeader from './ReportHeader.tsx';
import StyledTableContainer from './StyledTableContainer.tsx';
import TpgeStackedBarChart from './TpgeStackedBarChart.tsx';
import useReportHeader from './useReportHeader.tsx';

const groupByPaymentType = (data: ReportDataPoint[] = [], timePeriod: number) => {
  const stackedData = data.reduce<ReportDataPoint[]>((out, curr) => {
    let datapoint = out.find(f => f.timestamp === curr.timestamp);
    if (!datapoint) {
      datapoint = { timestamp: curr.timestamp }
      out.push(datapoint)
    }
    datapoint[curr.paymentType] = new Decimal(curr?.totalSales ?? 0).toFixed(2);

    return out
  }, [])
  return stackedData.map(m => ({...m, timestamp: formatDateForReport(parseCustomDate(m.timestamp), timePeriod)}));
}
const barKeys = ['Cash', 'Card', 'Stripe']

export default function TotalSalesReport() {
  const { date, setDate, timePeriod, setTimePeriod, view, handleViewChange, comparisonPeriods, setComparisonPeriods, clear } = useReportHeader()
  const { data, loading: isLoading } = useRequest(() => reportsApi.totalSales(date, timePeriod, comparisonPeriods), [date, timePeriod, comparisonPeriods]);

  const grouped: ReportDataPoint[] = groupByPaymentType(data ?? [], timePeriod)
  const reportData = padDataPoints(grouped ?? [], timePeriod, date, comparisonPeriods)

  for(const datapoint of grouped) {
    datapoint.total = new Decimal(datapoint.Cash ?? 0).plus(new Decimal(datapoint.Card ?? 0)).plus(new Decimal(datapoint.Stripe ?? 0)).toFixed(2)
  }
  const totalSales = grouped.reduce((sum, item) => sum.plus(new Decimal(item.total ?? 0)), new Decimal(0));

  return (
    <>
      <ReportHeader
        date={date}
        setDate={setDate}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
        comparisonPeriods={comparisonPeriods}
        setComparisonPeriods={setComparisonPeriods}
        view={view}
        handleViewChange={handleViewChange}
        clear={clear}
      />
      <LoadingSpinner isLoading={isLoading}>
        <>
          {view === ReportView.Chart ? (
            <TpgeStackedBarChart
              data={reportData}
              barKeys={barKeys}
              timePeriod={timePeriod}
            />
          ) : (
            <StyledTableContainer>
              <Table size="medium" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Date/Time</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>Card</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>Cash</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>Stripe</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {grouped.map((row) => (
                    <TableRow key={row.paymentType}>
                      <TableCell component="th" scope="row">
                        {row.timestamp}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{formatCurrency(row.Card ?? 0, true)}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{formatCurrency(row.Cash ?? 0, true)}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{formatCurrency(row.Stripe ?? 0, true)}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{formatCurrency(row.total ?? 0, true)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={3}/>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: 'bold', textAlign: 'right' }}
                    >
                      Total Sales
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                      {formatCurrency(totalSales, true)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
        </>
      </LoadingSpinner>
    </>
  );
}

