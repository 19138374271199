import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form';
import { formatCurrency } from '../../../../../common/moneyFunctions';
import {
  MutateProductVariant,
  TpgeComponent,
  TpgeComponentType,
} from '../../../../../common/types';
import AdminDialogTitle from '../../../components/AdminDialogTitle';

type ProductVariantModalProps = {
  productVariant: MutateProductVariant;
  onClose: () => void;
  onSave: (obj: MutateProductVariant) => void;
  components?: TpgeComponent[];
  componentTypes?: TpgeComponentType[];
};


const filterComponents = (
  components: TpgeComponent[],
  componentTypes: TpgeComponentType[],
  componentTypeId: number,
): TpgeComponent[] => {
  const selectedComponentType = componentTypes.find(
    (p) => p.id === componentTypeId,
  );
  if (componentTypeId < 1 || !selectedComponentType) {
    return [];
  }
  return components.filter(
    (component) => component.componentTypeId === selectedComponentType.id,
  );
};

export default function ProductVariantModal({
  productVariant,
  onClose,
  onSave,
  components = [],
  componentTypes = [],
}: ProductVariantModalProps) {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<MutateProductVariant & { componentTypeId: number }>({
    defaultValues: { ...productVariant, componentTypeId: components.find(f => f.id === productVariant.componentId)?.componentTypeId || 0 },
  });

  const watchComponentId = watch('componentId');
  const watchComponentTypeId = watch('componentTypeId');

  const selectedComponent = components.find(
    (component) => component.id === watchComponentId,
  );
  const units = selectedComponent?.unit || '';

  const onSubmit = (data: MutateProductVariant) => {
    data.productVariantSizeVolumes = data.productVariantSizeVolumes.map(
      (m) => ({
        ...m,
        volume: Number(m.volume),
        priceAdjustment: Number(m.priceAdjustment),
      }),
    );
    onSave(data);
    onClose();
  };

  const filteredComponents = filterComponents(
    components,
    componentTypes,
    watchComponentTypeId,
  );

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <AdminDialogTitle>
        {productVariant.id > 0 ? 'Edit Variant' : 'New Variant'}
      </AdminDialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                size="small"
                label="Name"
                type="text"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name="componentTypeId"
            control={control}
            rules={{
              required: true,
              validate: (value) =>
                value > 0 || 'Component Type is required',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                select
                margin="dense"
                size="small"
                label="Component Type"
                fullWidth
                error={!!errors.componentTypeId}
                helperText={errors.componentTypeId?.message}
                sx={{
                  '& .Mui-error.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall':
                  {
                    color: errors.componentTypeId
                      ? theme.palette.error.main
                      : 'currentColor',
                  },
                }}
              >
                {productVariant.id === 0 && (
                  <MenuItem value={0} disabled>
                    Select Type
                  </MenuItem>
                )}
                {componentTypes.map((ct) => (
                  <MenuItem key={ct.id} value={ct.id}>
                    {ct.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name={'isCore'}
            control={control}
            defaultValue={productVariant.isCore}
            render={({ field }) => (
              <FormControlLabel
                checked={field.value}
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(_, checked) => field.onChange(checked)}
                    onBlur={field.onBlur}
                    inputRef={field.ref}
                  />
                }
                label="Core Variant"
                sx={{
                  '& .MuiFormControlLabel-label.Mui-disabled': {
                    color: 'rgba(0,0,0,1)',
                  },
                }}
              />
            )}
          />

          <Controller
            name="componentId"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Component is required',
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                select
                margin="dense"
                size="small"
                label="Component"
                fullWidth
                disabled={watchComponentTypeId < 1}
                error={!!errors.componentId}
                helperText={errors.componentId?.message}
              >
                <MenuItem value={undefined} disabled>Select Component</MenuItem>
                {filteredComponents.map((component) => (
                  <MenuItem key={component.id} value={component.id}>
                    {component.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Grid
            container
            spacing={1}
            sx={{
              marginTop: 1,
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Grid size={3} sx={{ alignContent: 'baseline' }} />
            {productVariant.productVariantSizeVolumes.map((m) => (
              <Grid key={m.sizeName} size={3} sx={{ alignContent: 'baseline' }}>
                <Typography>{m.sizeName}</Typography>
              </Grid>
            ))}

            {!!selectedComponent && (
              <>
                <Grid size={3} sx={{ alignContent: 'baseline' }}>
                  <Typography>Volume in {units || 'Units'}</Typography>
                </Grid>
                {productVariant.productVariantSizeVolumes.map((m, idx) => (
                  <Grid
                    key={`${m.id}_${m.sizeName}_volume`}
                    size={3}
                    sx={{ alignContent: 'baseline' }}
                  >
                    {m.isActive && (
                      <Controller
                        name={`productVariantSizeVolumes.${idx}.volume`}
                        control={control}
                        defaultValue={m.volume}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            size="small"
                            margin="dense"
                            type="number"
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  </Grid>
                ))}
              </>
            )}
            <Grid size={3} sx={{ alignContent: 'baseline' }}>
              <Typography>Price Adjustment</Typography>
            </Grid>
            {productVariant.productVariantSizeVolumes.map((m, idx) => (
              <Grid
                key={`${m.id}_${m.sizeName}_priceAdjustment`}
                size={3}
                sx={{ alignContent: 'baseline' }}
              >
                {m.isActive && (
                  <Controller
                    name={`productVariantSizeVolumes.${idx}.priceAdjustment`}
                    control={control}
                    defaultValue={formatCurrency(m.priceAdjustment)}
                    render={({
                      field: { onChange, value, onBlur },
                      fieldState: { error },
                    }) => (
                      <TextField
                        size="small"
                        margin="dense"
                        type="text"
                        onChange={(e) => {
                          const rawValue = e.target.value.replace(
                            /[^0-9.]/g,
                            '',
                          );
                          onChange(rawValue);
                        }}
                        onBlur={() => {
                          const formattedValue = formatCurrency(value || 0);
                          onChange(formattedValue);
                          onBlur();
                        }}
                        fullWidth
                        value={value}
                        error={!!error}
                        helperText={error?.message}
                        slotProps={{
                          htmlInput: {
                            pattern: '[0-9]*(?:.[0-9]{0,2})?$',
                            placeholder: '0.00',
                          },
                          input: {
                            startAdornment: '$',
                          },
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
            ))}
            <Grid size={3} sx={{ alignContent: 'baseline', opacity: 0.5 }}>
              <Typography>Internal Cost</Typography>
            </Grid>
            {productVariant.productVariantSizeVolumes.map((m, idx) => {
              const volume = watch(`productVariantSizeVolumes.${idx}.volume`);
              const internalCost =
                +volume * (selectedComponent?.costPerUnit || 0);
              return (
                <Grid
                  key={`${m.id}_${m.sizeName}_internalCost`}
                  size={3}
                  sx={{ alignContent: 'baseline', opacity: 0.5 }}
                >
                  {m.isActive && internalCost > 0 && (
                    <Typography sx={{ paddingX: 1 }}>
                      ${formatCurrency(internalCost)}
                    </Typography>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
