import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { TpgeComponentType } from '../../../../../common/types';

interface FilterSectionProps {
  filterText: string;
  setFilterText: (value: string) => void;
  selectedComponentTypeId: number | undefined;
  setSelectedComponentTypeId: (value: number | undefined) => void;
  showDisabled: boolean;
  setShowDisabled: (value: boolean) => void;
  componentTypes: TpgeComponentType[] | undefined;
}

export function VariationFilter({
  filterText,
  setFilterText,
  selectedComponentTypeId,
  setSelectedComponentTypeId,
  showDisabled,
  setShowDisabled,
  componentTypes = [],
}: FilterSectionProps) {
  return (
    <Stack spacing={4} sx={{ padding: '2em 0' }}>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <TextField
          label="Filter by Name"
          size="small"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          variant="outlined"
          sx={{ flexGrow: 1, paddingRight: 2 }}
        />
        <TextField
          size="small"
          select
          label="Filter by Component Type"
          value={selectedComponentTypeId}
          onChange={(e) =>
            setSelectedComponentTypeId(
              e.target.value ? +e.target.value : undefined,
            )
          }
          variant="outlined"
          sx={{ minWidth: 200, paddingRight: 2 }}
        >
          <MenuItem value={undefined}>Any</MenuItem>
          {componentTypes.map((ct) => (
            <MenuItem key={ct.id} value={ct.id}>
              {ct.name}
            </MenuItem>
          ))}
        </TextField>
        <FormControlLabel
          control={
            <Switch
              checked={showDisabled}
              onChange={(e) => setShowDisabled(e.target.checked)}
            />
          }
          label="Show disabled variants"
        />
      </Stack>
    </Stack>
  );
}
