import RuleIcon from '@mui/icons-material/Rule';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ProductCategoryType } from '../../../../common/enums';
import { StaffOrder, StaffOrderItem } from '../../../../common/types';
import { IndicatorBox } from '../../components/IndicatorBox';
import PaidStatusIcon from '../../components/PaidStatusIcon';
import { allColdDrinksFinished, allColdDrinksPlaced, allColdDrinksStarted, allFoodFinished, allFoodPending, allFoodStarted, allHotCoffeeOrTeaFinished, allHotCoffeeOrTeaStarted } from '../../functions/stationFunctions';

interface Props {
    outstandingOrders: StaffOrder[]
}
export default function PartiallyCompleteOrders({
    outstandingOrders = [],
}: Props) {
    const theme = useTheme()
    if (outstandingOrders.length === 0) {
        return null
    }
    return (
        <>
            <Grid container sx={{ width: '100%' }}>
                {outstandingOrders.map(order => (
                    <Grid size={{ xs: 12 }} sx={{ width: '100%' }} key={order.id}>
                        <Paper
                            elevation={8}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                padding: 0,
                                backgroundColor: theme.palette.common.white,
                                color: theme.palette.common.deepblue,

                            }}
                        >
                            <Stack
                                sx={{
                                    backgroundColor: theme.palette.common.grey,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: 0.25,
                                }}
                            >
                                <Stack
                                    sx={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        padding: 0,
                                    }}
                                >
                                    <Stack
                                        sx={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Stack
                                            sx={{ padding: 1, alignItems: 'center' }}
                                        >
                                            <RuleIcon />
                                        </Stack>
                                        <Divider orientation="vertical" flexItem />
                                        <Typography
                                            variant="h3"
                                            sx={{ marginLeft: theme.spacing(1) }}
                                        >
                                            {order.customerName}
                                        </Typography>
                                    </Stack>

                                    <Stack direction="row" sx={{ paddingRight: 2 }}>
                                        <PaidStatusIcon isPaid={!!order.isPaid} />
                                        {order.orderItems.some(
                                            (item: StaffOrderItem) =>
                                                item.productCategoryType ===
                                                ProductCategoryType.HotCoffee ||
                                                item.productCategoryType ===
                                                ProductCategoryType.Tea,
                                        ) && (
                                                <IndicatorBox
                                                    orderItems={order.orderItems}
                                                    label="Hot Coffee"
                                                    allStartedFn={allHotCoffeeOrTeaStarted}
                                                    allFinishedFn={allHotCoffeeOrTeaFinished}
                                                    allPendingFn={allHotCoffeeOrTeaFinished}
                                                />
                                            )}
                                        {order.orderItems.some(
                                            (item: StaffOrderItem) =>
                                                item.productCategoryType ===
                                                ProductCategoryType.ColdDrinks,
                                        ) && (
                                                <IndicatorBox
                                                    orderItems={order.orderItems}
                                                    label="Cold Drinks"
                                                    allStartedFn={allColdDrinksStarted}
                                                    allFinishedFn={allColdDrinksFinished}
                                                    allPendingFn={allColdDrinksPlaced}
                                                />
                                            )}
                                        {order.orderItems.some(
                                            (item: StaffOrderItem) =>
                                                item.productCategoryType ===
                                                ProductCategoryType.Food,
                                        ) && (
                                                <IndicatorBox
                                                    orderItems={order.orderItems}
                                                    label="Food"
                                                    allStartedFn={allFoodStarted}
                                                    allFinishedFn={allFoodFinished}
                                                    allPendingFn={allFoodPending}
                                                />
                                            )}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Divider />
        </>
    )
}