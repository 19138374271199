/* eslint-disable @typescript-eslint/no-explicit-any */
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React, { useRef, useState } from 'react';
import productsApi from '../../../api/product';
import { hasAdminRole } from '../../../common/roleFunctions';
import { isStaff } from '../../../common/typeAssertionFunctions';
import { MutateProduct, Product, Size } from '../../../common/types';
import useProductCategories from '../../../datastore/useProductCategories';
import useProducts from '../../../datastore/useProducts';
import useSizes from '../../../datastore/useSizes';
import useAlert from '../../../hooks/useAlert';
import useAPI from '../../../hooks/useAPI';
import { AdminHeader } from '../components/AdminHeader';
import ConfirmModal from '../../../components/modal/ConfirmModal';
import useLoadingModal from '../../../hooks/useLoadingModal';
import { ProductFilter } from './components/ProductFilter';
import ProductModal from './components/ProductModal';
import { ProductTable } from './components/ProductTable';
import { mapProductToMutateProduct } from './functions/productFunctions';

const productsPerPage = 200;

const newMutateProduct = (sizes: Size[] = [], siteId = 1): MutateProduct => ({
  id: 0,
  description: '',
  name: '',
  isActive: true,
  productCategoryId: 0,
  siteId,
  productSizes: sizes
    .sort((a, b) => b.name.localeCompare(a.name))
    .map((m) => ({
      id: 0,
      sizeId: m.id,
      basePrice: 0,
      isActive: true,
      isDefault: m.isDefault,
      sizeName: m.name,
    })),
});

const filterProducts = (
  products: Product[],
  nameFilter: string,
  showDisabled: boolean,
  categoryId: number,
): Product[] => {
  const lowerNameFilter = nameFilter?.toLowerCase().trim() ?? '';
  return products.filter(
    (product) =>
      (showDisabled || product.isActive) &&
      (lowerNameFilter?.length > 0
        ? product.name.toLowerCase().includes(lowerNameFilter)
        : true) &&
      (categoryId > 0 ? product.productCategoryId === categoryId : true),
  );
};

export default function ProductsPage() {
  const { currentUser } = useAPI();
  const {
    products = [],
    loading: productsLoading,
    fetchProducts: refreshProducts,
    showDisabled,
    setShowDisabled,
  } = useProducts();
  const { setLoading } = useLoadingModal()
  const { sizes = [], loading: sizesLoading } = useSizes();
  const { productCategories, loading: productCategoriesLoading } =
    useProductCategories();
  const { showAlert } = useAlert();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [isMutating, setIsMutating] = useState(false);
  const [filterText, setFilterText] = useState<string>('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [mutateProductId, setMutateProductId] = useState<number | null>(null);
  const [productToArchiveId, setProductToArchiveId] = useState<number | null>(null);

  const productToMutate: Product | undefined = products?.find(
    (f) => f.id === mutateProductId,
  );
  const mutateProduct: MutateProduct = productToMutate
    ? mapProductToMutateProduct(productToMutate, sizes)
    : newMutateProduct(sizes, 1);

  const isLoading =
    isMutating || productsLoading || sizesLoading || productCategoriesLoading;

  if (!hasAdminRole(currentUser) || !isStaff(currentUser)) return null;

  async function onArchiveProductConfirmed(id: number) {
    try {
      setLoading(true)
      await productsApi.archive(id);
      refreshProducts();
      setProductToArchiveId(null);
      showAlert('Product archived successfully', 'success')
    } catch(err: any) {
      console.error(err)
      showAlert(err.data ?? err.message ?? 'Error archiving product', 'error')
    } finally {
      setLoading(false)
    }
  }

  async function handleMutate(mutateProduct: MutateProduct) {
    const isUpdate = mutateProduct.id > 0;
    const mutateFn = isUpdate
      ? productsApi.updateProduct
      : productsApi.createProduct;
    const successText = `Product ${isUpdate ? 'updated' : 'created'} successfully`;
    const errorText = `Product ${isUpdate ? 'update' : 'creation'} failed`;
    try {
      setIsMutating(true);
      await mutateFn(mutateProduct);
      refreshProducts();
      showAlert(successText, 'success');
      setMutateProductId(null);
    } catch (err) {
      showAlert(errorText, 'error');
      console.error(err);
    } finally {
      setIsMutating(false);
    }
  }

  async function statusToggle(product: Product) {
    const updatedProduct = { ...product, isActive: !product.isActive };
    await handleMutate(mapProductToMutateProduct(updatedProduct, sizes));
  }

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }

  const filteredProducts = filterProducts(
    products || [],
    filterText || '',
    showDisabled,
    selectedCategoryId,
  );

  const totalNumberOfPages = Math.ceil(
    filteredProducts.length / productsPerPage,
  );

  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(
    startIndex + productsPerPage,
    filteredProducts.length,
  );
  const paginatedProducts = filteredProducts.slice(startIndex, endIndex);
  const productToArchive = products?.find(f => f.id === productToArchiveId)

  return (
    <Stack
      sx={{
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <AdminHeader
        title="Products"
        description="Manage your products, click a product to manage its variations."
        icon={<ShoppingCartIcon fontSize="large" sx={{ color: 'white' }} />}
        buttonText="New Product"
        buttonAction={() => setMutateProductId(0)}
      />

      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <ProductFilter
          filterText={filterText}
          setFilterText={setFilterText}
          selectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={(categoryId) => {
            setSelectedCategoryId(categoryId);
            setCurrentPage(1);
          }}
          showDisabled={showDisabled}
          toggleShowDisabled={() => setShowDisabled(!showDisabled)}
          productCategories={productCategories || []}
        />
        <Box
          ref={tableContainerRef}
          sx={{
            flex: 1,
            overflowY: 'auto',
            minHeight: 0,
          }}
        >
          <ProductTable
            isLoading={isLoading}
            paginatedProducts={paginatedProducts}
            statusToggle={statusToggle}
            editProduct={(product) => setMutateProductId(product.id)}
            onArchiveClicked={(product) => setProductToArchiveId(product.id)}
          />
        </Box>
        <Box
          sx={{
            borderTop: 1,
            borderColor: 'divider',
            py: 2,
            backgroundColor: 'background.paper',
          }}
        >
          <Stack spacing={2} direction="row" justifyContent="center">
            <Pagination
              count={totalNumberOfPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="medium"
            />
          </Stack>
        </Box>
      </Stack>

      {mutateProductId !== null && (
        <ProductModal
          onClose={() => setMutateProductId(null)}
          onSave={handleMutate}
          product={mutateProduct}
          isLoading={isMutating}
          productCategories={productCategories || []}
          sizes={sizes || []}
        />
      )}

      {productToArchive && (
        <ConfirmModal
          title={`Archive ${productToArchive.name}?`}
          onClose={() => setProductToArchiveId(null)}
          onConfirm={() => onArchiveProductConfirmed(productToArchive.id)}
          content="Are you sure you want to PERMANENTLY ARCHIVE this product? All associated product sizes, product variants and popular products will also be disabled"
        />
      )}
    </Stack>
  );
}
