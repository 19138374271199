import { compareAsc } from 'date-fns/compareAsc';
import {
  OrderItemStatus,
  ProductCategoryType,
  TimerWarningColors,
} from '../../../common/enums';
import { StaffOrder, StaffOrderItem } from '../../../common/types';

export function allCategoryItemsAreStatus(
  items: StaffOrderItem[],
  categories: ProductCategoryType[],
  status: OrderItemStatus,
): boolean {
  return items
    .filter((item) =>
      categories.includes(item.productCategoryType as ProductCategoryType),
    )
    .every((item) => item.status === status);
}

export function allOrderItemsFinished(orderItems: StaffOrderItem[]): boolean {
  return orderItems.every((item) => item.status === OrderItemStatus.Finished);
}

export function anyFoodPending(orderItems: StaffOrderItem[]): boolean {
  return orderItems
    .filter((item) => item.productCategoryType === ProductCategoryType.Food)
    .some((item) => item.status === OrderItemStatus.Placed);
}

export function allHotCoffeeOrTeaPlaced(orderItems: StaffOrderItem[]): boolean {
  return allCategoryItemsAreStatus(
    orderItems,
    [ProductCategoryType.HotCoffee, ProductCategoryType.Tea],
    OrderItemStatus.Placed,
  );
}

export function allHotCoffeeOrTeaStarted(
  orderItems: StaffOrderItem[],
): boolean {
  return allCategoryItemsAreStatus(
    orderItems,
    [ProductCategoryType.HotCoffee, ProductCategoryType.Tea],
    OrderItemStatus.Started,
  );
}

export function allHotCoffeeOrTeaFinished(
  orderItems: StaffOrderItem[],
): boolean {
  return allCategoryItemsAreStatus(
    orderItems,
    [ProductCategoryType.HotCoffee, ProductCategoryType.Tea],
    OrderItemStatus.Finished,
  );
}

export function allColdDrinksPlaced(orderItems: StaffOrderItem[]): boolean {
  return allCategoryItemsAreStatus(
    orderItems,
    [ProductCategoryType.ColdDrinks],
    OrderItemStatus.Placed,
  );
}

export function allColdDrinksFinished(orderItems: StaffOrderItem[]): boolean {
  return allCategoryItemsAreStatus(
    orderItems,
    [ProductCategoryType.ColdDrinks],
    OrderItemStatus.Finished,
  );
}

export function allColdDrinksStarted(orderItems: StaffOrderItem[]): boolean {
  return allCategoryItemsAreStatus(
    orderItems,
    [ProductCategoryType.ColdDrinks],
    OrderItemStatus.Started,
  );
}

export function allFoodPending(orderItems: StaffOrderItem[]): boolean {
  return allCategoryItemsAreStatus(
    orderItems,
    [ProductCategoryType.Food],
    OrderItemStatus.Placed,
  );
}

export function allFoodStarted(orderItems: StaffOrderItem[]): boolean {
  return allCategoryItemsAreStatus(
    orderItems,
    [ProductCategoryType.Food],
    OrderItemStatus.Started,
  );
}

export function allFoodFinished(orderItems: StaffOrderItem[]): boolean {
  return allCategoryItemsAreStatus(
    orderItems,
    [ProductCategoryType.Food],
    OrderItemStatus.Finished,
  );
}

export function getPickupTimeColor(requestedPickupTime: Date): string {
  const currentTime = new Date();
  const timeDifference = requestedPickupTime.getTime() - currentTime.getTime();
  const fiveMinutesInMs = 5 * 60 * 1000;

  if (timeDifference <= 0) {
    return TimerWarningColors.Green;
  } else if (timeDifference < fiveMinutesInMs) {
    return TimerWarningColors.Orange;
  } else {
    return TimerWarningColors.Red;
  }
}

export function getQueueOrder(
  orderItems: StaffOrderItem[],
  productTypes: ProductCategoryType[],
): number {
  if (!Array.isArray(orderItems)) {
    console.warn(
      'Expected orderItems to be an array, but received:',
      orderItems,
    );
    return 1;
  }

  for (const productType of productTypes) {
    const item = orderItems.find(
      (item) => item.productCategoryType === productType,
    );
    if (item && item.queueOrder) return item.queueOrder;
  }

  return 1;
}

export function sortStationOrders(
  orders: StaffOrder[],
  stationProductCategories: ProductCategoryType[]
): StaffOrder[] {
  return orders.sort((a, b) => {
    // started come before unstarted - otherwise started orders will be at the bottom of the list and possibly off screen
    const aUnstarted = allCategoryItemsAreStatus(a.orderItems, stationProductCategories, OrderItemStatus.Placed);
    const bUnstarted = allCategoryItemsAreStatus(b.orderItems, stationProductCategories, OrderItemStatus.Placed);
    if (aUnstarted !== bUnstarted) {
      return aUnstarted ? 1 : -1;
    }

    // Compare based on queue order
    const aQueueOrder = getQueueOrder(a.orderItems, stationProductCategories);
    const bQueueOrder = getQueueOrder(b.orderItems, stationProductCategories);

    // Handle null queue orders
    if (aQueueOrder === null && bQueueOrder === null) {
      // If both are null, move to the next comparison
    } else if (aQueueOrder === null) {
      return 1; // Null queue orders should come after non-null
    } else if (bQueueOrder === null) {
      return -1;
    } else {
      const queueOrderComparison = aQueueOrder - bQueueOrder;
      if (queueOrderComparison !== 0) {
        return queueOrderComparison;
      }
    }

    // Compare based on requested pickup time (lowest priority)
    if (a.requestedPickupTime !== null && b.requestedPickupTime !== null) {
      return compareAsc(
        new Date(a.requestedPickupTime),
        new Date(b.requestedPickupTime),
      );
    } else if (
      a.requestedPickupTime === null &&
      b.requestedPickupTime !== null
    ) {
      return 1;
    } else if (
      a.requestedPickupTime !== null &&
      b.requestedPickupTime === null
    ) {
      return -1;
    }

    return 0;
  });
}
