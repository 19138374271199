/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ProductCategoryType } from '../../../../common/enums';
import { sortOrdersById } from '../../../../common/sortFunctions';
import { StaffOrder } from '../../../../common/types';
import InfoCard from '../../../../components/card/InfoCard';
import useAlert from '../../../../hooks/useAlert';
import {
  getOrderPosition,
  handleFinishOrder,
  handleStartStopOrder,
  shiftOrderItems,
} from '../../functions/orderFunctions';
import {
  allHotCoffeeOrTeaPlaced,
  allHotCoffeeOrTeaStarted
} from '../../functions/stationFunctions';
import DraggableDrinkOrderCard from './DraggableDrinkOrderCard';
import StationHeader from './StationHeader';

interface PrimaryStationProps {
  primaryOrders: StaffOrder[];
}

export default function PrimaryStation({
  primaryOrders,
  children
}: PropsWithChildren<PrimaryStationProps>) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useAlert();
  const [draggableOrders, setDraggableOrders] = useState<StaffOrder[]>([]);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  })
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  })
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  })
  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    keyboardSensor,
  );

  useEffect(() => {
    const incomingOrdersAreDifferent = !isEqual(
      sortOrdersById(primaryOrders),
      sortOrdersById(draggableOrders),
    );
    const incomingOrders = [...primaryOrders];
    if (incomingOrdersAreDifferent) {
      incomingOrders.forEach((incomingOrder) => {
        const draggableOrder = draggableOrders.find(
          (dragOrder) => dragOrder.id === incomingOrder.id,
        );
        incomingOrder.orderItems.forEach((incomingOrderItem) => {
          const foundDraggableOrderItem = draggableOrder?.orderItems.find(
            (dragOrderItem) => dragOrderItem.id === incomingOrderItem.id,
          );
          if (foundDraggableOrderItem) {
            incomingOrderItem.queueOrder = foundDraggableOrderItem.queueOrder;
          }
        });
      });
      setDraggableOrders([...primaryOrders]);
      setIsLoading(false);
    }
  }, [primaryOrders]);

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id === over.id) return;

    setDraggableOrders((prevOrders) => {
      const originalPosition = getOrderPosition(active.id, draggableOrders);
      const newPosition = getOrderPosition(over.id, draggableOrders);
      const newArray = arrayMove(prevOrders, originalPosition, newPosition);
      shiftOrderItems(
        newArray,
        [ProductCategoryType.HotCoffee, ProductCategoryType.Tea],
        showAlert,
      );
      return newArray;
    });
  }

  const sortedFilteredOrders = draggableOrders.map((order) => {
    const isAllHotCoffeePlaced = allHotCoffeeOrTeaPlaced(order.orderItems);
    const isAllHotCoffeeStarted = allHotCoffeeOrTeaStarted(order.orderItems);

    return {
      id: order.id, // draggable items need an id
      order,
      isAllHotCoffeePlaced,
      isAllHotCoffeeStarted,
    };
  });

  return (

    <Grid
      size={{ xs: 10, md: 8 }}
      sx={{
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StationHeader />
      <Divider />
      {sortedFilteredOrders.length === 0 ? (
        <InfoCard
          Icon={NotificationsIcon}
          title="No Results Found"
          subtitle="Try refreshing your page or check back later."
        />
      ) : (
        <Box
          sx={{
            overflowY: 'auto',
            height: 'calc(100vh - 64px)',
          }}
        >
          <Grid container spacing={1} sx={{ padding: 3 }}>
            {children}
            <DndContext
              collisionDetection={closestCorners}
              sensors={sensors}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext
                items={sortedFilteredOrders}
                strategy={verticalListSortingStrategy}
              >
                {sortedFilteredOrders.map(
                  (
                    {
                      order,
                      isAllHotCoffeePlaced,
                      isAllHotCoffeeStarted,
                    }
                  ) => {
                    return (
                      <Grid size={12} key={order.id}>
                        <Paper
                          elevation={8}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            padding: 0,
                            margin: 0,
                            height: '100%',
                            backgroundColor: theme.palette.common.white,
                            color: theme.palette.common.deepblue,
                          }}
                        >
                          <DraggableDrinkOrderCard
                            order={order}
                            isAllDrinksPlaced={isAllHotCoffeePlaced}
                            isAllDrinksStarted={isAllHotCoffeeStarted}
                            isColdDrinks={false}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            handleStartOrder={() => {
                              handleStartStopOrder(
                                order,
                                true,
                                [
                                  ProductCategoryType.HotCoffee,
                                  ProductCategoryType.Tea,
                                ],
                                showAlert,
                                setIsLoading,
                              );
                            }}
                            handleStopOrder={() => {
                              handleStartStopOrder(
                                order,
                                false,
                                [
                                  ProductCategoryType.HotCoffee,
                                  ProductCategoryType.Tea,
                                ],
                                showAlert,
                                setIsLoading,
                              );
                            }}
                            handleFinishOrder={() =>
                              handleFinishOrder(
                                order,
                                [
                                  ProductCategoryType.HotCoffee,
                                  ProductCategoryType.Tea,
                                ],
                                showAlert,
                                setIsLoading,
                              )
                            }
                          />

                          {/* )} */}
                        </Paper>
                      </Grid>
                    );
                  },
                )}
              </SortableContext>
            </DndContext>
          </Grid>
        </Box>
      )
      }
    </Grid >
  );
}
