import Decimal from 'decimal.js';
import { GST, OrderAdapterType } from './enums';
import {
  CalculableItem,
  OrderItem,
  Product,
  ProductSize,
  ProductVariant,
  StaffOrderItem,
} from './types';
import { adaptOrderItem, adaptStaffOrderItem } from './adapterFunctions';

export function calculatePriceDifferenceWithSign(
  priceAdjustment: number | string,
): string {
  const difference = new Decimal(priceAdjustment).toFixed(2);
  const sign = new Decimal(difference).gte(0) ? '+' : '-';
  return `${sign}$${new Decimal(difference).abs().toFixed(2)}`;
}

export const productBasePrice = (product: Product): Decimal => {
  const activeDefault = product.productSizes.find(
    (f) => f.isActive && f.isDefault,
  );
  const fallback = product.productSizes.find((f) => f.isActive);
  return new Decimal(activeDefault?.basePrice || fallback?.basePrice || 0);
};

export const productVariantPriceAdjustment = (
  productVariant: ProductVariant,
  productSize: ProductSize,
): Decimal => {
  const sizeVolume = productVariant.productVariantSizeVolumes.find(
    (f) => f.productSizeId === productSize.id,
  );
  return new Decimal(sizeVolume?.priceAdjustment || 0);
};

export function displayPrice(price: Decimal | number | string): string {
  return `$${new Decimal(price).toFixed(2)}`;
}

export function calculateSubtotalAndGSTFromTotal(total: string | number | Decimal) {
  const decimalTotal = new Decimal(total);
  const gst = decimalTotal.times(GST.TenPercent);
  const subtotal = decimalTotal.minus(gst);
  return { subtotal, gst };
}

export function calculateGSTBreakdown(orderItems: OrderItem[]): {
  total: Decimal;
  gst: Decimal;
  subtotal: Decimal;
} {
  const total = calculateTotalPrice(orderItems);
  const { subtotal, gst } = calculateSubtotalAndGSTFromTotal(total);
  return { total, subtotal, gst };
}

export const formatCurrency = (
  value: string | number | Decimal,
  withSign: boolean = false,
): string => {
  const decimalValue = new Decimal(value);
  const absValue = decimalValue.abs();

  if (!withSign) {
    return `${absValue.toFixed(2)}`;
  }

  return decimalValue.isNegative()
    ? `-$${absValue.toFixed(2)}`
    : `$${absValue.toFixed(2)}`;
};

export const calculateProductInternalCost = (
  product: Product,
  productSizeId: number,
): Decimal => {
  const coreVariants = product.productVariants.filter((f) => f.isCore);

  let total = new Decimal(0);
  for (const cv of coreVariants) {
    total = total.plus(calculateProductVariantInternalCost(cv, productSizeId));
  }
  return total;
};

export const calculateProductVariantInternalCost = (
  productVariant: ProductVariant,
  productSizeId: number,
): Decimal => {
  const pvsv = productVariant.productVariantSizeVolumes.find(
    (f) => f.productSizeId === productSizeId,
  );
  if (pvsv && productVariant.component) {
    return new Decimal(pvsv.volume).times(
      new Decimal(productVariant.component.costPerUnit),
    );
  }
  return new Decimal(0);
};

function calculateItem(item: CalculableItem): Decimal {
  if (item.type === OrderAdapterType.Staff) {
    const basePrice = new Decimal(item.basePrice);
    const totalAdjustment = item.selectedVariants.reduce((acc, variant) => {
      const sizeVolume = variant.productVariant.productVariantSizeVolumes.find(
        (vol) => vol.productSizeId === item.productSizeId,
      );
      if (!sizeVolume) return acc;
      return acc.plus(sizeVolume.priceAdjustment);
    }, new Decimal(0));
    return basePrice.plus(totalAdjustment);
  } else {
    const productSize = item.sizeOptions.find(
      (f) => f.id === item.productSizeId,
    );
    if (!productSize) return new Decimal(0);

    const totalAdjustment = item.selectedVariants.reduce((acc, variant) => {
      const pvsv = variant.productVariantSizeVolumes.find(
        (f) => f.productSizeId === item.productSizeId,
      );
      if (!pvsv) return acc;
      return acc.plus(pvsv.priceAdjustment);
    }, new Decimal(0));
    return new Decimal(productSize.basePrice).plus(totalAdjustment);
  }
}

export function calculateItemPrice(item: OrderItem): Decimal {
  return calculateItem(adaptOrderItem(item));
}

export function calculateStaffItemPrice(item: StaffOrderItem): Decimal {
  return calculateItem(adaptStaffOrderItem(item));
}

export function calculateTotalPrice(orderItems: OrderItem[]): Decimal {
  return orderItems.reduce((acc, item) => {
    return acc.plus(calculateItemPrice(item));
  }, new Decimal(0));
}

export function calculateStaffTotalPrice(
  orderItems: StaffOrderItem[],
): Decimal {
  return orderItems.reduce((acc, item) => {
    return acc.plus(calculateStaffItemPrice(item));
  }, new Decimal(0));
}
