import { fromZonedTime } from 'date-fns-tz';
import { toZonedTime } from 'date-fns-tz/toZonedTime';
import { endOfWeek } from 'date-fns/endOfWeek';
import { format } from 'date-fns/format';
import { isWithinInterval } from 'date-fns/isWithinInterval';
import { startOfMonth } from 'date-fns/startOfMonth';
import { startOfWeek } from 'date-fns/startOfWeek';

const australiaPerthTz = 'Australia/Perth';

export function awstFromUtc(date: Date): Date {
  const dateInAwst = toZonedTime(date, australiaPerthTz);
  return dateInAwst
}

export function awstToUtc(date: Date): Date {
  const dateInUtc = fromZonedTime(date, australiaPerthTz);
  return dateInUtc
}

export function formatTime(date: Date): string {
  return format(date, 'h:mm a'); // 12-hour format with AM/PM
}

export function formatDate(date: Date): string {
  return format(date, 'eee, d MMM yyyy h:mm a');
}

export function formatDateForReport(date: Date, timePeriod: number): string {
  switch (timePeriod) {
    case 1: return format(date, 'eee, d MMM yyyy')
    case 2: return format(startOfWeek(date, { weekStartsOn: 1 }), 'eee, d MMM yyyy')
    case 3: return format(startOfMonth(date), 'MMM yyyy')
    default: return formatDate(date)
  }
}

export function isInActiveWindow(date: Date): boolean {
  const dateInAwst = awstFromUtc(date);
  const start = earliestPickupTime(dateInAwst)
  const end = latestPickupTime(dateInAwst)
  return isWithinInterval(dateInAwst, { start, end })
}

export function areCustomerOrderingHoursActive(): boolean {
  const now = new Date()
  return isInActiveWindow(now)
}

export function isPickupTimeWithinActiveWindow(pickupTime: Date): boolean {
  return isInActiveWindow(pickupTime)
}

export function earliestPickupTime(date: Date): Date {
  const dateInAwst = awstFromUtc(date);
  const start = new Date(dateInAwst)
  start.setHours(4, 30, 0, 0)
  return start
}

export function latestPickupTime(date: Date): Date {
  const dateInAwst = awstFromUtc(date);
  const end = new Date(dateInAwst)
  end.setHours(18, 0, 0, 0)
  return end
}

export function startOfDayWA(date: Date): Date {
  const dateInAwst = awstFromUtc(date);
  dateInAwst.setHours(0, 0, 0, 0);
  return dateInAwst
}

export function startOfWeekWA(date: Date): Date {
  const dateInAwst = awstFromUtc(date);
  dateInAwst.setHours(0, 0, 0, 0);
  return startOfWeek(dateInAwst, { weekStartsOn: 1 })
}
export function endOfWeekWA(date: Date): Date {
  const dateInAwst = awstFromUtc(date);
  dateInAwst.setHours(0, 0, 0, 0);
  return endOfWeek(dateInAwst, { weekStartsOn: 1 })
}