import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import DoDisturbOnRoundedIcon from '@mui/icons-material/DoDisturbOnRounded';
import TimelapseRoundedIcon from '@mui/icons-material/TimelapseRounded';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TimerWarningColors } from '../../../common/enums';
import { StaffOrderItem } from '../../../common/types';

interface IndicatorBoxProps {
  orderItems: StaffOrderItem[];
  label: string;
  allStartedFn: (orderItems: StaffOrderItem[]) => boolean;
  allFinishedFn: (orderItems: StaffOrderItem[]) => boolean;
  allPendingFn: (orderItems: StaffOrderItem[]) => boolean;
}

function getStatusIcon(
  orderItems: StaffOrderItem[],
  allStartedFn: (orderItems: StaffOrderItem[]) => boolean,
  allFinishedFn: (orderItems: StaffOrderItem[]) => boolean,
  allPendingFn: (orderItems: StaffOrderItem[]) => boolean,
) {
  if (allFinishedFn(orderItems)) {
    return (
      <CheckCircleOutlineRoundedIcon sx={{ color: TimerWarningColors.Green }} />
    );
  } else if (allStartedFn(orderItems) && !allPendingFn(orderItems)) {
    return <TimelapseRoundedIcon sx={{ color: TimerWarningColors.Orange }} />;
  } else {
    return <DoDisturbOnRoundedIcon sx={{ color: TimerWarningColors.Red }} />;
  }
}

export function IndicatorBox({
  orderItems,
  label,
  allStartedFn,
  allFinishedFn,
  allPendingFn,
}: IndicatorBoxProps) {
  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
      <Stack sx={{ paddingLeft: 2.5 }}>
        {getStatusIcon(orderItems, allStartedFn, allFinishedFn, allPendingFn)}
      </Stack>
      <Typography variant="caption" sx={{ paddingLeft: 0.5, paddingTop: 0.5 }}>
        {label}
      </Typography>
    </Stack>
  );
}
