/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { InternalAxiosRequestConfig } from 'axios';
import { apiUrl } from '../common/constants';
import LocalStorageSvc, { JWT_KEY } from '../common/localStorageSvc';

axios.interceptors.request.use(
  function (
    request: InternalAxiosRequestConfig<any>,
  ): InternalAxiosRequestConfig<any> {
    const jwt = LocalStorageSvc.load(JWT_KEY);
    if (jwt) {
      request.headers.set('Authorization', `Bearer ${jwt}`);
    }
    request.headers.set('Content-Type', 'application/json');
    return request;
  },
  function (error: any): any | null | undefined {
    console.error(error);
  },
);

export async function getRequest(endpoint: string) {
  try {
    const url = `${apiUrl}${endpoint}`;
    const response = await axios.get(url);
    return response;
  } catch (error: any) {
    console.error(error);
    throw error.response
  }
}

export async function postRequest(endpoint: string, data: any) {
  try {
    const url = `${apiUrl}${endpoint}`;
    return await axios.post(url, data);
  } catch (error: any) {
    console.error(error.response);
    throw error.response
  }
}

export async function putRequest(endpoint: string, data: any) {
  try {
    const url = `${apiUrl}${endpoint}`;
    return await axios.put(url, data);
  } catch (error: any) {
    console.error(error.response);
    throw error.response
  }
}

export async function deleteRequest(endpoint: string) {
  try {
    const url = `${apiUrl}${endpoint}`;
    return await axios.delete(url);
  } catch (error: any) {
    console.error(error.response);
    throw error.response
  }
}

export async function patchRequest(endpoint: string, data: any) {
  try {
    const url = `${apiUrl}${endpoint}`;
    return await axios.patch(url, data);
  } catch (error: any) {
    console.error(error.response);
    throw error.response
  }
}
