import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  Elements,
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  StripeElementsOptions,
  StripeExpressCheckoutElementOptions,
} from '@stripe/stripe-js';
import Decimal from 'decimal.js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  completeOrder,
  createStripePaymentIntent,
} from '../../../api/customerOrders';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import useAlerts from '../../../hooks/useAlert';
import useLoadingModal from '../../../hooks/useLoadingModal';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

interface PaymentSectionProps {
  amount: Decimal;
  orderId: number;
}

interface PaymentInnerProps {
  amount: number;
  orderId: number;
}

function PaymentInner({ amount, orderId }: PaymentInnerProps) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [ready, setReady] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { setLoading } = useLoadingModal();
  const { showAlert } = useAlerts();

  const onConfirm = async (): Promise<void> => {
    try {
      setLoading(true);
      setErrorMessage(null);
      if (!stripe || !elements) {
        // Stripe.js hasn't loaded yet.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const { error: submitError } = await elements.submit();
      if (submitError?.message) {
        setErrorMessage(submitError!.message);
        showAlert(submitError!.message, 'error');
        return;
      }

      const paymentIntentResponse = await createStripePaymentIntent(amount);
      const clientSecret = paymentIntentResponse.data?.client_secret;
      if (!clientSecret) {
        showAlert('Couldn\'t create payment token', 'error');
        setErrorMessage('Couldn\'t create payment token');
        return;
      }

      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: location.origin,
        },
        redirect: 'if_required',
      });
      if (error || paymentIntent?.status !== 'succeeded') {
        const message: string =
          error?.message ||
          `${error?.type}` ||
          `Payment failed :( ${paymentIntent?.status}`;
        setErrorMessage(message);
        showAlert(message, 'error');
        return;
      }

      const completeOrderResponse = await completeOrder(
        orderId,
        paymentIntent.id,
      );
      if (completeOrderResponse.status !== 200) {
        showAlert('Payment confirmation error :(', 'error');
        setErrorMessage('Payment confirmation error :(');
      } else {
        showAlert('Payment complete!', 'success');
        navigate(`/orderconfirmed/${orderId}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const expressCheckoutOptions: StripeExpressCheckoutElementOptions = {
    // Specify a type per payment method
    // Defaults to 'buy' for Google and 'plain' for Apple
    buttonType: {
      googlePay: 'order',
      applePay: 'order',
    },
    // Specify a theme per payment method
    // Default theme is based on appearance API settings
    buttonTheme: {
      applePay: 'white-outline',
    },
    // Height in pixels. Defaults to 44. The width is always '100%'.
    buttonHeight: 40,
    paymentMethods: {
      applePay: 'always',
      googlePay: 'always',
    },
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="center">
        <LoadingSpinner isLoading={!ready} />
      </Stack>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      <ExpressCheckoutElement
        onConfirm={onConfirm}
        options={expressCheckoutOptions}
        onReady={() => setReady(true)}
      />
    </>
  );
}

export default function PaymentSection({
  amount: total,
  orderId,
}: PaymentSectionProps) {
  const stripeTotal = total.toDecimalPlaces(2).times(100).toNumber();

  const stripePaymentOptions: StripeElementsOptions = {
    mode: 'payment',
    amount: stripeTotal,
    currency: 'aud',
  };

  return (
    <Elements
      key={`${total}`}
      stripe={stripePromise}
      options={stripePaymentOptions}
    >
      <PaymentInner amount={stripeTotal} orderId={orderId} />
    </Elements>
  );
}
