import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ordersApi from '../../../api/order';
import { ProductCategoryType, Stations } from '../../../common/enums';
import {
  getOrderItemKey,
  mapProductToOrderItem,
} from '../../../common/orderFunctions';
import { isStaff } from '../../../common/typeAssertionFunctions';
import { Order, OrderItem } from '../../../common/types';
import MenuTabs from '../../../components/menu/MenuTabs';
import OrderItemVariationsModal from '../../../components/modal/OrderItemVariationsModal';
import useProducts from '../../../datastore/useProducts';
import useStations from '../../../datastore/useStations';
import useAPI from '../../../hooks/useAPI';
import useAlert from '../../../hooks/useAlert';
import MenuCards from './components/MenuCards';
import MenuHeader from './components/MenuHeader';
import OrderFor from './components/OrderFor';

export default function OrderPage() {
  const { currentUser } = useAPI();
  const {
    products = [],
    loading: productsLoading,
    fetchProducts,
  } = useProducts();
  const { stations: stationData = [], loading: stationsLoading } =
    useStations();

  const productData = products.filter(f => f.isActive)

  const theme = useTheme();
  const { showAlert } = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const initialOrderData: Order = {
    id: 0,
    startTime: null,
    finishTime: null,
    isPaid: false,
    siteId: isStaff(currentUser) ? currentUser.siteId : 0,
    orderItems: [],
    requestedPickupTime: new Date(),
  };

  const [searchProduct, setSearchProduct] = useState('');
  const [editOrderItemId, setEditOrderItemId] = useState<number | null>(null);
  const [isNewItemBeingEdited, setIsNewItemBeingEdited] = useState(false);
  const [orderData, setOrderData] = useState<Order>(initialOrderData);
  const [selectedCategory, setSelectedCategory] = useState(
    ProductCategoryType.HotCoffee,
  );

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const setPaymentType = (val: string) => setOrderData(prev => ({...prev, paymentType: val}))

  const walkUpReturnRoute: string = location.state?.returnRoute
    ? location.state.returnRoute
    : '';

  const isLoading = productsLoading || stationsLoading;

  if (!isStaff(currentUser)) return null;

  function handleCategoryChange(
    _: React.SyntheticEvent,
    newValue: ProductCategoryType,
  ) {
    setSelectedCategory(newValue);
  }

  function handleStationChange(stationName: string) {
    if (orderData.orderItems.length === 0) {
      showAlert('No items in order to assign to station', 'warning');
      return;
    }

    const stationId = stationData?.find(
      (station) => station.name === stationName,
    )?.id;

    if (!stationId) {
      showAlert(
        'Could not assign to station, please try again or revise manually after order is placed.',
        'warning',
      );
      console.info(
        `No station id found for station name: ${stationName}, please try again.`,
      );
      return;
    }

    const orderItemsWithStation: OrderItem[] = orderData.orderItems.map(
      (item) => ({
        ...item,
        stationId: stationId,
      }),
    );
    setOrderData({ ...orderData, orderItems: orderItemsWithStation });
  }

  function handleRemoveOrderItem(id: number) {
    const filteredOrderItems = orderData.orderItems.filter(
      (item) => item.id !== id,
    );
    setOrderData({ ...orderData, orderItems: filteredOrderItems });
    showAlert('Item removed from order', 'success');
  }

  function handleAddOrderItem(item: OrderItem) {
    const newItem: OrderItem = {
      ...item,
      id: new Date().getTime() * -1,
      selectedVariants: [...item.selectedVariants],
    };

    setOrderData({
      ...orderData,
      orderItems: [...orderData.orderItems, newItem],
    });
  }

  function handleSizeChange(id: number, newSizeId: number) {
    const orderItem = orderData.orderItems.find((item) => item.id === id);
    if (!orderItem) return;

    const newProductSize = productData
      ?.find((product) => product.id === orderItem.productId)
      ?.productSizes.find((productSize) => productSize.id === newSizeId);

    if (!newProductSize) {
      showAlert('Failed to assign size, try again', 'warning');
      console.error('Failed to assign size, try again');
      return;
    }

    const key = getOrderItemKey(orderItem);
    const currentGroup = orderData.orderItems.filter(
      (x) => key === getOrderItemKey(x),
    );

    const updatedOrderItems = orderData.orderItems.map((item) =>
      currentGroup.some((groupItem) => groupItem.id === item.id)
        ? {
          ...item,
          productSizeId: newProductSize.id,
        }
        : item,
    );

    setOrderData({ ...orderData, orderItems: updatedOrderItems });
  }

  function handleSubmitVariationModal(updatedOrderItem: OrderItem | null) {
    if (!updatedOrderItem) {
      setEditOrderItemId(null);
      setIsNewItemBeingEdited(false);
      return;
    }

    const originalItem = orderData.orderItems.find(
      (item) => item.id === updatedOrderItem.id,
    );
    if (!originalItem) {
      setEditOrderItemId(null);
      setIsNewItemBeingEdited(false);
      return;
    }

    if (isNewItemBeingEdited) {
      const updatedOrderItems = orderData.orderItems.map((item) =>
        item.id === updatedOrderItem.id ? updatedOrderItem : item,
      );
      setOrderData({ ...orderData, orderItems: updatedOrderItems });
      setEditOrderItemId(null);
      setIsNewItemBeingEdited(false);
      return;
    }

    const key = getOrderItemKey(originalItem);
    const currentGroup = orderData.orderItems.filter(
      (x) => key === getOrderItemKey(x),
    );

    const updatedOrderItems = orderData.orderItems.map((item) => {
      if (currentGroup.some((groupItem) => groupItem.id === item.id)) {
        return {
          ...item,
          selectedVariants: updatedOrderItem.selectedVariants,
          notes: updatedOrderItem.notes,
        };
      }
      return item;
    });

    setOrderData({ ...orderData, orderItems: updatedOrderItems });

    if (originalItem.productSizeId !== updatedOrderItem.productSizeId) {
      currentGroup.forEach((groupItem) => {
        handleSizeChange(groupItem.id, updatedOrderItem.productSizeId);
      });
    }

    setEditOrderItemId(null);
    setIsNewItemBeingEdited(false);
  }

  function handleShowEditModal(id: number) {
    setIsNewItemBeingEdited(false);
    setEditOrderItemId(id);
  }

  function addOrderItem(productId: number) {
    const product = productData?.find((product) => product.id === productId);
    if (product === undefined) {
      showAlert('Failed to add item, try again', 'warning');
      return;
    }
    const orderItem = mapProductToOrderItem(product);
    orderItem.id = new Date().getTime() * -1;
    setOrderData({
      ...orderData,
      requestedPickupTime: new Date(),
      orderItems: [...orderData.orderItems, orderItem],
    });
    setIsNewItemBeingEdited(true);
    setEditOrderItemId(orderItem.id);
  }

  function getStationId(category: ProductCategoryType): number | null {
    const foodStation = stationData?.find(
      (station) => station.name === Stations.Food,
    );
    const coldStation = stationData?.find(
      (station) => station.name === Stations.ColdDrinks,
    );
    if (!foodStation || !coldStation) {
      showAlert('Failed to assign station, please refresh page', 'error');
      return null;
    }
    switch (category) {
      case ProductCategoryType.ColdDrinks:
        return coldStation.id;
      case ProductCategoryType.Food:
        return foodStation.id;
      default:
        throw new Error('Invalid product category');
    }
  }

  function getAssignedStation() {
    const teaOrCoffeeItem = orderData.orderItems.find(
      (item) =>
        item.productCategoryType === ProductCategoryType.Tea ||
        item.productCategoryType === ProductCategoryType.HotCoffee,
    );

    if (!teaOrCoffeeItem || !teaOrCoffeeItem.stationId) {
      return Stations.StationOne;
    }

    const station = stationData?.find(
      (station) => station.id === teaOrCoffeeItem.stationId,
    );
    return station?.name || Stations.StationOne;
  }

  function prepareOrderDataForSubmission(
    orderData: Order,
    customerName: string,
  ): Order {
    const order = { ...orderData };
    order.customerName = customerName;
    order.requestedPickupTime = new Date();
    order.orderItems.forEach((item) => {
      if (item.id < 0) {
        item.id = 0;
      }
      let stationId: number | null = item.stationId;
      if (
        item.productCategoryType !== ProductCategoryType.HotCoffee &&
        item.productCategoryType !== ProductCategoryType.Tea
      ) {
        stationId = getStationId(item.productCategoryType);
        if (!stationId) {
          console.error('Failed to assign station to order item');
          return;
        }
      }
      item.stationId = stationId;
    });
    return order;
  }

  async function handleSubmitOrder(customerName: string) {
    const preparedData = prepareOrderDataForSubmission(orderData, customerName);
    const result = await ordersApi.create(preparedData);
    if (result.status === 200) {
      showAlert('Order submitted', 'success');
      setOrderData(initialOrderData);
    } else {
      showAlert('Failed to submit order, please try again.', 'warning');
    }
    if (walkUpReturnRoute) {
      navigate(walkUpReturnRoute);
    }
  }

  const editOrderItem = orderData.orderItems.find(
    (f) => f.id === editOrderItemId,
  );

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.common.white,
        height: '100vh',
      }}
    >
      <Grid
        size={{ xs: 12, md: 7 }}
        sx={{
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <MenuHeader
          searchProduct={searchProduct}
          setSearchProduct={setSearchProduct}
        />
        <Divider sx={{ paddingBottom: 1 }} />
        <Stack>
          <MenuTabs
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
          <MenuCards
            isLoading={isLoading}
            productData={productData}
            searchProduct={searchProduct}
            orderItems={orderData.orderItems}
            selectedCategory={selectedCategory}
            addOrderItem={addOrderItem}
          />
        </Stack>
      </Grid>
      <OrderFor
        orderData={orderData}
        products={productData ?? []}
        assignedStation={getAssignedStation}
        removeOrderItem={handleRemoveOrderItem}
        changeOrderItemSize={handleSizeChange}
        changeOrderStation={handleStationChange}
        editOrderItemVariations={handleShowEditModal}
        setPaymentType={setPaymentType}
        submitOrder={handleSubmitOrder}
        addOrderItem={handleAddOrderItem}
      />
      {!!editOrderItem && (
        <OrderItemVariationsModal
          item={editOrderItem}
          onClose={handleSubmitVariationModal}
          submitText="Save Changes"
        />
      )}
    </Grid>
  );
}
