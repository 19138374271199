import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import ordersApi from '../../../../api/order';
import { emailRules } from '../../../../common/formValidationRules';
import { StaffOrder } from '../../../../common/types';
import Button from '../../../../components/button/StyledButton';
import useAlert from '../../../../hooks/useAlert';

interface ReceiptModalProps {
    order: StaffOrder
    onClose: () => void
}

const validateEmail = emailRules().validate

export default function ReceiptModal({ order, onClose }: ReceiptModalProps) {
    const { showAlert } = useAlert()
    const {
        register,
        handleSubmit,
        formState: { errors, touchedFields }
    } = useForm();
    const [loading, setLoading] = useState(false)

    const handleSendReceipt = async (data: FieldValues) => {
        if (order.id && validateEmail(data.email) === true) {
            try {
                setLoading(true)
                const result = await ordersApi.sendReceipt(order.id, data.email)
                if (result.status !== 200) {
                    console.error(result)
                    throw new Error(`Receipt request failed: ${result.status}`)
                }
                onClose()
                showAlert('Receipt sent', 'success')
            } catch (err) {
                console.error(err)
                showAlert('Failed to send receipt', 'error')
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    sx={{ paddingBottom: '0.5em' }}
                >
                    Send Receipt
                </Typography>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography sx={{ paddingBottom: '1em' }} variant="body2">
                        Send a receipt to the customer via email
                    </Typography>
                </DialogContentText>
                <Box component="form" onSubmit={handleSubmit(handleSendReceipt)} noValidate sx={{ flex: 1 }}>
                    <TextField
                        fullWidth
                        type="email"
                        label="Email Address"
                        placeholder="someone@example.com"
                        {...register('email', {
                            required: 'Email is required',
                            validate: {
                                validEmail: value =>
                                    !value || validateEmail(value) || 'Please enter a valid email address'
                            }
                        })}
                        error={touchedFields.email && Boolean(errors.email)}
                        helperText={touchedFields.email && errors.email?.message}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose} disabled={loading}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit(handleSendReceipt)} disabled={loading || touchedFields.email && Boolean(errors.email)}>
                    {
                        loading ? <CircularProgress size={24} /> : 'Send'
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}
