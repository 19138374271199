import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import ordersApi from '../../../../api/order';
import { ProductCategoryType, Stations } from '../../../../common/enums';
import {
  groupStaffOrderItems
} from '../../../../common/orderFunctions';
import { sortOrdersById } from '../../../../common/sortFunctions';
import { Staff, StaffOrder, Station } from '../../../../common/types';
import ConfirmModal from '../../../../components/modal/ConfirmModal';
import useOrders from '../../../../datastore/useOrders';
import useAlert from '../../../../hooks/useAlert';
import PaidStatusIcon from '../../components/PaidStatusIcon';
import { TimeIndicator } from '../../components/TimeIndicator';
import {
  allHotCoffeeOrTeaStarted
} from '../../functions/stationFunctions';
import StationHeader from './StationHeader';

interface OpposingStationProps {
  opposingOrders: StaffOrder[];
  user: Staff;
  stations: Station[];
}

export default function OpposingStation({
  opposingOrders,
  user,
  stations,
}: OpposingStationProps) {
  const theme = useTheme();
  const { showAlert } = useAlert();
  const [takeOrderId, setTakeOrderId] = useState<number | null>(null);
  const [coffeeOrders, setCoffeeOrders] =
    useState<StaffOrder[]>(opposingOrders);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchOrders } = useOrders()

  useEffect(() => {
    const incomingOrdersAreDifferent = !isEqual(
      sortOrdersById(opposingOrders),
      sortOrdersById(coffeeOrders),
    );
    if (incomingOrdersAreDifferent) {
      setCoffeeOrders(opposingOrders);
      setIsLoading(false);
    }
  }, [opposingOrders]);

  async function handleTakeOrder() {
    if (!takeOrderId || !user.stationId) {
      console.error('Failed to take order due to invalid order or station id');
      showAlert('Failed to take order', 'error');
      return;
    }

    const result = await ordersApi.updateStation({
      orderId: takeOrderId,
      newStationId: user.stationId,
      productCategories: [
        ProductCategoryType.HotCoffee,
        ProductCategoryType.Tea,
      ],
    });
    await fetchOrders()

    if (result.status !== 200) {
      console.error('Failed to take order, server error');
      showAlert('Failed to take order', 'error');
    }
  }

  let opposingStationId = 0;
  const currentStation = stations.find(
    (station) => station.id === user.stationId,
  );
  if (currentStation) {
    const opposingStationName =
      currentStation.name === Stations.StationOne
        ? Stations.StationTwo
        : Stations.StationOne;
    opposingStationId =
      stations.find((station) => station.name === opposingStationName)?.id || 0;
  }

  const sortedFilteredOrders = coffeeOrders.map((order) => {
    const isAllHotCoffeeStarted = allHotCoffeeOrTeaStarted(order.orderItems);
    const filteredOrderItems = order.orderItems.filter(
      (orderItem) => orderItem.stationId === opposingStationId,
    );
    const groupedItems = groupStaffOrderItems(filteredOrderItems, true);
    return { id: order.id, order, isAllHotCoffeeStarted, groupedItems };
  });

  return (
    <Grid
      size={{
        xs: 2,
        md: 4,
      }}
      sx={{
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StationHeader isOpposingStation />
      <Divider />
      <Box
        sx={{
          overflowY: 'auto',
          height: 'calc(100vh - 64px)',
        }}
      >
        <Grid container spacing={1} sx={{ padding: 3 }}>
          {sortedFilteredOrders.map(
            ({ order, isAllHotCoffeeStarted, groupedItems }) => {
              return (
                <Grid size={12} key={order.id}>
                  <Paper
                    elevation={8}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      padding: 0,
                      margin: 0,
                      height: '100%',
                      backgroundColor: theme.palette.common.white,
                      color: theme.palette.common.deepblue,
                    }}
                  >
                    <Stack
                      sx={{
                        backgroundColor: theme.palette.common.grey,
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        padding: 0,
                      }}
                    >
                      <Stack
                        sx={{ flexDirection: 'row', alignItems: 'center', marginRight: 'auto' }}
                      >
                        {isAllHotCoffeeStarted && (
                          <>
                            <Stack sx={{ padding: 1, alignItems: 'center' }}>
                              <LockIcon />
                            </Stack>
                            <Divider orientation="vertical" flexItem />
                          </>
                        )}
                        <Typography
                          variant="h3"
                          sx={{ marginLeft: theme.spacing(1) }}
                        >
                          {order.customerName}
                        </Typography>
                      </Stack>
                      <PaidStatusIcon isPaid={!!order.isPaid} />
                      <TimeIndicator time={order.requestedPickupTime} />
                    </Stack>
                    <TableContainer component={Paper}>
                      <Table aria-label="order table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Qty</TableCell>
                            <TableCell>Type</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupedItems.map((orderItemWithQty, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>
                                  {orderItemWithQty.quantity}
                                </TableCell>
                                <TableCell>
                                  {orderItemWithQty.productName}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      <Stack
                        sx={{
                          alignItems: 'center',
                        }}
                      >
                        {!isAllHotCoffeeStarted && (
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              margin: 1,
                              width: '20em',
                            }}
                            onClick={() => {
                              setIsLoading(true);
                              setTakeOrderId(order.id ?? '');
                            }}
                            disabled={isLoading}
                          >
                            Take Order
                          </Button>
                        )}
                      </Stack>
                    </TableContainer>
                  </Paper>
                </Grid>
              );
            },
          )}
        </Grid>
      </Box>
      {takeOrderId && (
        <ConfirmModal
          onClose={() => {
            setTakeOrderId(null);
            setIsLoading(false);
          }}
          title="Take Order"
          content="Are you sure you want to take this order?"
          onConfirm={() => {
            handleTakeOrder();
            setTakeOrderId(null);
          }}
        />
      )}
    </Grid>
  );
}
