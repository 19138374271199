import { SvgIconComponent } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

interface InfoCardProps {
  Icon: SvgIconComponent;
  title: string;
  subtitle: string;
}

export default function InfoCard({ Icon, title, subtitle }: InfoCardProps) {
  const theme = useTheme();
  return (
    <Paper
      elevation={8}
      sx={{
        width: '70%',
        alignSelf: 'center',
        marginTop: '4em !important',
        backgroundColor: theme.palette.common.grey,
        color: theme.palette.common.offWhite,
        marginX: 'auto',
      }}
    >
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ padding: '1em' }}
      >
        <Icon color="primary" />
        <Typography
          fontWeight="bold"
          sx={{ color: theme.palette.primary.main }}
        >
          {title}
        </Typography>
        <Typography sx={{ color: theme.palette.primary.main }}>
          {subtitle}
        </Typography>
      </Stack>
    </Paper>
  );
}
