export const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g;
export const phoneRegex = /^(\+61|0)([2-478])([0-9]{8})$/;

/* eslint-disable no-control-regex */
// need control characters in this email validation regex
export const emailRegex =
  /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;

export const emailRules = () => ({
  validate: (value: string) => emailRegex.test(value) || 'Invalid email format',
});

const enum PasswordFails {
  Length = 'Password must be at least 8 characters long',
  Uppercase = 'Password must contain at least one uppercase letter',
  Lowercase = 'Password must contain at least one lowercase letter',
  Number = 'Password must contain at least one number',
  Special = 'Password must contain at least one special character',
}

const enum ConfirmPasswordFails {
  Match = 'The passwords don\'t match',
}

export type PasswordValidationRules =
  | { required: boolean }
  | {
      validate: (value: string) => boolean | PasswordFails;
    }
  | {
      validate: (value: string) => boolean | ConfirmPasswordFails;
    };

export const passwordRules = (): PasswordValidationRules => ({
  validate: (value: string) => {
    if (value.length < 8) {
      return PasswordFails.Length;
    }
    if (!/[A-Z]/.test(value)) {
      return PasswordFails.Uppercase;
    }
    if (!/[a-z]/.test(value)) {
      return PasswordFails.Lowercase;
    }
    if (!/[0-9]/.test(value)) {
      return PasswordFails.Number;
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return PasswordFails.Special;
    }
    return true;
  },
});

export const phoneRules = () => ({
  validate: (value: string) => {
    if (!value?.length) {
      return true;
    }
    return phoneRegex.test(value) || 'Invalid phone number';
  },
});

export const confirmPasswordRules = (password: string) => ({
  validate: (value: string) => value === password || ConfirmPasswordFails.Match,
});
