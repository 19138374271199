/* eslint-disable @typescript-eslint/no-explicit-any */
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import componentTypesApi from '../../../../api/componentTypes';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';
import ConfirmModal from '../../../../components/modal/ConfirmModal';
import useComponentTypes from '../../../../datastore/useComponentTypes';
import useAlert from '../../../../hooks/useAlert';
import useLoadingModal from '../../../../hooks/useLoadingModal';
import useRequest from '../../../../hooks/useRequest';
import { AdminHeader } from '../../components/AdminHeader';

export default function ComponentTypeArchive() {
    const { componentTypeId } = useParams();
    const id = Number(componentTypeId);
    const { componentTypes, fetchComponentTypes } = useComponentTypes();
    const { setLoading } = useLoadingModal()
    const { data: components, loading: componentsLoading } = useRequest(() => componentTypesApi.activeComponentsFor(id), [componentTypeId], isNaN(id))
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { showAlert } = useAlert()
    useEffect(() => { fetchComponentTypes() }, [])

    const componentType = (componentTypes || []).find(c => c.id === id);
    const hasActiveComponents = (components || []).length > 0;

    const confirmArchive = () => {
        if (hasActiveComponents) {
            showAlert('There are still active components using this component type', 'error')
            return
        }
        setShowConfirmModal(true)
    }

    async function archive() {
        try {
            setLoading(true)
            await componentTypesApi.archive(id);
            fetchComponentTypes();
            setShowConfirmModal(false);
            showAlert('ComponentType archived successfully', 'success')
        } catch (err: any) {
            console.error(err)
            showAlert(err.data ?? err.message ?? 'Error archiving ComponentType', 'error')
        } finally {
            setLoading(false)
        }
    }



    return (
        <Stack
            sx={{
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <AdminHeader
                title={'Archive Component Type'}
                description={`Permanently archive ${componentType?.name ?? 'this component type'}`}
                icon={<ShoppingCartIcon fontSize="large" sx={{ color: 'white' }} />}
                buttonText="Archive"
                buttonAction={confirmArchive}
            />
            <Stack
                sx={{
                    flex: 1,
                    overflow: 'hidden',
                    padding: 2,
                }}
            >
                <Typography variant='h2'>Active Components</Typography>
                <Typography variant='body1'>Archiving is not supported with active components{hasActiveComponents ? ', please review' : ''}</Typography>
                <Link href='/admin/componenttypes'>Back to Component Types</Link>
                <Table sx={{ maxWidth: '100%', overflowX: 'auto' }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Component</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            componentsLoading ? (
                                <TableRow>
                                    <TableCell>
                                        <LoadingSpinner isLoading />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                (components || []).map((component) => {
                                    return (
                                        <TableRow
                                            key={component.id}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                },
                                            }}
                                        >
                                            <TableCell><Link sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start'
                                            }} href={`/admin/components?componentType=${id}`}>{component?.name} <ArrowRightAltIcon /></Link></TableCell>
                                        </TableRow>
                                    )
                                })
                            )
                        }
                        {
                            !componentsLoading && !components?.length && (
                                <TableRow>
                                    <TableCell sx={{ textAlign: 'center' }}>No active components</TableCell>
                                </TableRow>
                            )
                        }

                    </TableBody>
                </Table>
            </Stack>
            {
                showConfirmModal && (
                    <ConfirmModal
                        title={`Archive ${componentType?.name ?? 'this component'}`}
                        content={`Are you sure you want to PERMANENTLY ARCHIVE ${componentType?.name ?? 'this component'}?`}
                        onClose={() => setShowConfirmModal(false)}
                        onConfirm={archive}
                    />
                )
            }
        </Stack>
    )
}