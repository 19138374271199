const createDing = (audioContext: AudioContext, frequency: number, delay: number) => {
  return new Promise((resolve) => {
    // Create oscillator and gain node
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();
    const startTime = audioContext.currentTime + delay

    // Configure oscillator
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(frequency, startTime);

    // Create volume envelope
    gainNode.gain.setValueAtTime(0, startTime);
    gainNode.gain.linearRampToValueAtTime(0.5, startTime + 0.05);
    gainNode.gain.linearRampToValueAtTime(0, startTime + 0.2);

    // Connect nodes
    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    // Start and stop oscillator
    oscillator.start(startTime);
    oscillator.stop(startTime + 0.2);

    // Clean up and resolve when done
    oscillator.onended = () => {
      oscillator.disconnect();
      gainNode.disconnect();
      resolve(void 0);
    };
  });
};

export const playFindMySoundAsync = async (): Promise<void> => {
  const audioContext = new window.AudioContext()

  const promise = new Promise<void>((resolve, reject) => {
    const dingSeries = [
      { frequency: 880, delay: 0 },     // A5
      { frequency: 1046, delay: 0.2 },  // C6
      { frequency: 1318, delay: 0.3 },  // E6
    ];

    const playDingSeries = async () => {
      try {
        for (const ding of dingSeries) {
          await createDing(audioContext, ding.frequency, ding.delay);
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    };
    playDingSeries();
  });

  return promise
};