import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React, { useEffect, useRef, useState } from 'react';
import { hasAdminRole } from '../../../common/roleFunctions';
import { isStaff } from '../../../common/typeAssertionFunctions';
import useCustomers from '../../../datastore/useCustomers';
import useAlert from '../../../hooks/useAlert';
import useAPI from '../../../hooks/useAPI';
import { AdminHeader } from '../components/AdminHeader';
import AdminPageFilter from '../components/AdminPageFilter';
import CustomerManagementTable from './components/CustomerManagementTable';

const CustomersPerPage = 200;

export default function CustomerManagementPage() {
  const { currentUser } = useAPI();
  const { customers = [], loading: isLoading, error } = useCustomers();
  const { showAlert } = useAlert();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [filterText, setFilterText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (error) {
      showAlert('Error retrieving customers, refresh and try again.', 'error');
    }
  }, [error]);

  if (!hasAdminRole(currentUser) || !isStaff(currentUser)) return null;

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }
  const filteredCustomers =
    customers.filter((customer) =>
      filterText
        ? customer.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
          customer.lastName.toLowerCase().includes(filterText.toLowerCase())
        : true,
    ) || [];

  const totalNumberOfPages = Math.ceil(
    filteredCustomers.length / CustomersPerPage,
  );

  const startIndex = (currentPage - 1) * CustomersPerPage;
  const endIndex = Math.min(
    startIndex + CustomersPerPage,
    filteredCustomers.length,
  );
  const paginatedCustomers = filteredCustomers.slice(startIndex, endIndex);

  return (
    <Stack
      sx={{
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <AdminHeader
        title="Customers"
        description="View your customers details."
        icon={<PersonIcon fontSize="large" sx={{ color: 'white' }} />}
      />

      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <AdminPageFilter
          filterText={filterText}
          setFilterText={setFilterText}
        />

        <Box
          ref={tableContainerRef}
          sx={{
            flex: 1,
            overflowY: 'auto',
            minHeight: 0,
          }}
        >
          <CustomerManagementTable
            isLoading={isLoading}
            paginatedCustomers={paginatedCustomers}
          />
        </Box>

        <Box
          sx={{
            borderTop: 1,
            borderColor: 'divider',
            py: 2,
            backgroundColor: 'background.paper',
          }}
        >
          <Stack spacing={2} direction="row" justifyContent="center">
            <Pagination
              count={totalNumberOfPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="medium"
            />
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
