import Decimal from 'decimal.js';
import { calculateItemPrice } from '../common/moneyFunctions';
import { getOrderItemKey } from '../common/orderFunctions';
import {
  OrderItem,
  ProductSize,
  ProductVariant,
  ProductVariantWithQty,
  TpgeComponentType,
} from '../common/types';


const getVariationsOfComponentType = (variants: ProductVariant[], componentType: TpgeComponentType): [ProductVariant[], boolean] => {
  const filtered = variants.filter(variant => variant.isActive && variant.component?.componentTypeId === componentType.id)
  return [filtered, !!filtered.length]
}

const removeAllVariantsOfComponentType = (
  selectedVariants: ProductVariant[],
  componentTypeId: number,
): ProductVariant[] => {
  return selectedVariants.filter(
    (variant) => variant.component?.componentTypeId !== componentTypeId,
  );
};

const orderHasSelectedVariant = (
  localItem: OrderItem,
  variant: ProductVariant,
): boolean => {
  return !!localItem.selectedVariants.find((f) => f.id === variant.id);
};


export class OrderItemViewModel {
  private readonly item: OrderItem;
  public static readonly NO_MILK = 'No Milk';
  public static readonly SELECT_SUGAR = 'Add sugar';
  public static readonly SELECT_SYRUP = 'Add syrup';
  public allowsSizeVariations = false;

  public shotVariations: ProductVariant[] = [];
  public allowsShotsVariation = false;

  public milkVariations: ProductVariant[] = [];
  public allowsMilkVariations = false;

  public sugarVariations: ProductVariant[] = [];
  public allowsSugarVariations = false;

  public syrupVariations: ProductVariant[] = [];
  public allowsSyrupVariations = false;

  public otherVariations: ProductVariant[] = [];
  public allowsOtherVariations = false;

  public beansVariations: ProductVariant[] = [];
  public allowsBeansVariations = false;
  public showBeansVariations = false;
  public hasDecafOption = false;

  public selectedSize: ProductSize;
  public selectedMilk: ProductVariant | undefined;
  public selectedBean: ProductVariant | undefined;
  public selectedShot: ProductVariant | undefined;
  public selectedSugar: ProductVariant | undefined;

  private readonly beansType: TpgeComponentType;
  private readonly milkType: TpgeComponentType;
  private readonly shotsType: TpgeComponentType;
  private readonly sugarsType: TpgeComponentType;
  private readonly syrupsType: TpgeComponentType;

  public get isDecafSelected() { return !!this.selectedBean?.name.toLowerCase().includes('decaf') }

  public shotCount: number = 0;

  public variationsWithQty: Record<string, ProductVariantWithQty> = {};

  public notes: string = '';

  public total: Decimal = new Decimal(0);

  constructor(item: OrderItem, beansType: TpgeComponentType | null, milkType: TpgeComponentType | null, shotsType: TpgeComponentType | null, sugarsType: TpgeComponentType | null, syrupsType: TpgeComponentType | null) {
    this.item = { ...item };
    if (!beansType || !milkType || !shotsType || !sugarsType || !syrupsType) {
      console.error('one of these is null', { beansType, milkType, shotsType, sugarsType, syrupsType });
      throw new Error('Missing component types');
    }
    this.beansType = beansType;
    this.milkType = milkType;
    this.shotsType = shotsType;
    this.sugarsType = sugarsType;
    this.syrupsType = syrupsType;

    this.notes = this.item.notes;

    this.allowsSizeVariations =
      this.item.sizeOptions.filter((option) => option.isActive).length > 1;
    this.selectedSize =
      this.item.sizeOptions.find(
        (option) => option.id === this.item.productSizeId,
      ) || this.item.sizeOptions[0];

    this.initMilk();
    this.initBeans();
    this.initShots();
    this.initSyrups();
    this.initSugars();

    this.setVariationAdjustments();

    this.total = calculateItemPrice(this.item);
  }

  private initMilk() {
    const [mv, allowsMV] = getVariationsOfComponentType(
      this.item.allowedVariants,
      this.milkType,
    );
    this.milkVariations = mv;
    this.allowsMilkVariations = allowsMV;
    this.selectedMilk = this.item.selectedVariants.find(
      (variant) =>
        variant.component?.componentTypeId === this.milkType.id,
    );
  }

  private initBeans() {
    const [abv, allowsBV] = getVariationsOfComponentType(
      this.item.allowedVariants,
      this.beansType
    );
    this.beansVariations = abv;
    this.allowsBeansVariations = allowsBV;
    this.showBeansVariations = allowsBV && abv.length > 1;
    this.hasDecafOption = !!abv.find(f => f.name.toLowerCase().includes('decaf'))
    this.determineBeanVariant();
    this.addDefaultBeans();
  }

  /** initBeans before initShots */
  private initShots() {
    const [sv, allowsSV] = getVariationsOfComponentType(
      this.item.allowedVariants,
      this.shotsType,
    );
    this.shotVariations = sv;
    this.allowsShotsVariation = allowsSV;

    this.determineShotVariant();

    this.shotCount = this.item.selectedVariants.filter(
      (variant) =>
        variant.component?.componentTypeId === this.shotsType.id,
    ).length;
  }
  private initSyrups() {
    const [syrups, allowSyrups] = getVariationsOfComponentType(this.item.allowedVariants, this.syrupsType)
    this.syrupVariations = syrups;
    this.allowsSyrupVariations = allowSyrups
  }

  private initSugars() {
    const [sugars, allowSugars] = getVariationsOfComponentType(this.item.allowedVariants, this.sugarsType)
    this.sugarVariations = sugars;
    this.allowsSugarVariations = allowSugars
    this.selectedSugar = this.item.selectedVariants.find(selectedVariant => sugars.some(sugar => sugar.id === selectedVariant.id))
  }

  private determineBeanVariant() {
    const existing = this.item.selectedVariants.find(
      (f) => f.component?.componentTypeId === this.beansType.id,
    );
    if (existing) {
      this.selectedBean = existing;
    } else if (this.beansVariations.length) {
      this.selectedBean =
        this.beansVariations.find((f) => f.isCore) || this.beansVariations[0];
    } else {
      this.selectedBean = undefined;
    }
  }

  private addDefaultBeans() {
    if (!this.allowsBeansVariations) {
      return
    }
    if (
      this.selectedBean && !this.item.selectedVariants.find(
        (f) => f.component?.componentTypeId === this.beansType.id,
      )
    ) {
      this.item.selectedVariants = [...this.item.selectedVariants, { ...this.selectedBean }]
    }
  }

  private determineShotVariant() {
    if (this.selectedBean?.component) {
      this.selectedShot = this.isDecafSelected
        ? this.shotVariations.find(f => f.component?.name.toLowerCase().includes('decaf'))
        : this.shotVariations.find(f => !f.component?.name.toLowerCase().includes('decaf'))

      if (this.selectedBean && !this.selectedShot) {
        console.info(
          'determineShotVariant: failed to locate an Extra Shot option for ',
          this.selectedBean.name,
        );
      }
    }
  }

  private setVariationAdjustments() {
    this.variationsWithQty = this.item.selectedVariants.reduce(
      (output, variant, i, arr) => {
        const firstIndexByVariantId = arr.findIndex(
          (v) => v.id === variant.id,
        );
        if (firstIndexByVariantId !== i) {
          return output;
        }
        const allMatchingVariants = arr.filter((v) => v.id === variant.id);

        return {
          ...output,
          [variant.id]: {
            ...variant,
            quantity: allMatchingVariants.length,
          },
        };
      },
      {},
    );
  }

  public addSugar(variantId: string | number): OrderItemViewModel {
    return this.addVariant(variantId)
  }

  public addVariant(variantId: number | string): OrderItemViewModel {
    const newVariant = this.item.allowedVariants.find(
      (v) => v.id === variantId
    )
    if (variantId === OrderItemViewModel.NO_MILK || newVariant?.component?.componentTypeId === this.milkType.id) {
      this.item.selectedVariants = removeAllVariantsOfComponentType(this.item.selectedVariants, this.milkType.id)
    } else if (newVariant?.component?.componentTypeId === this.beansType.id) {
      this.item.selectedVariants = removeAllVariantsOfComponentType(this.item.selectedVariants, this.beansType.id)
      this.item.selectedVariants = removeAllVariantsOfComponentType(this.item.selectedVariants, this.shotsType.id)
    } else if (newVariant?.component?.componentTypeId === this.sugarsType.id && this.selectedSugar?.id && this.selectedSugar.id !== Number(variantId)) {
      this.item.selectedVariants = removeAllVariantsOfComponentType(this.item.selectedVariants, this.sugarsType.id)
    }

    if (newVariant) {
      this.item.selectedVariants = [...this.item.selectedVariants, { ...newVariant }]
    }
    return this.fromItem(this.item);
  }

  public removeVariant(
    variantId: number | string | undefined,
  ): OrderItemViewModel {
    if (variantId === undefined) {
      return this.fromItem(this.item);
    }

    const numericVariantId = Number(variantId);

    const lastIndex = this.item.selectedVariants
      .map((v) => v.id)
      .lastIndexOf(numericVariantId);

    if (lastIndex > -1) {
      this.item.selectedVariants.splice(lastIndex, 1);
    } else {
      console.info('Selected variation not found');
    }

    return this.fromItem(this.item);
  }

  public hasSelectedVariant(variant: ProductVariant): boolean {
    return orderHasSelectedVariant(this.item, variant);
  }

  public setNotes(newNotes: string): OrderItemViewModel {
    this.item.notes = newNotes;
    return this.fromItem(this.item);
  }

  public setSize(sizeId: string | number): OrderItemViewModel {
    this.item.productSizeId = +sizeId;
    return this.fromItem(this.item);
  }

  private fromItem(item: OrderItem): OrderItemViewModel {
    return new OrderItemViewModel(item, this.beansType, this.milkType, this.shotsType, this.sugarsType, this.syrupsType);
  }

  public toItem(): OrderItem {
    return this.item;
  }

  public itemEqualsOrderItem(orderItem: OrderItem): boolean {
    const thisItemKey = getOrderItemKey(this.item);
    const otherOrderItemKey = getOrderItemKey(orderItem);
    return thisItemKey.localeCompare(otherOrderItemKey) === 0;
  }
}
