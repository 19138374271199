import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fragment, useState } from 'react';
import {
  calculatePriceDifferenceWithSign,
  formatCurrency,
  productVariantPriceAdjustment,
} from '../../common/moneyFunctions';
import { OrderItem } from '../../common/types';
import useComponentTypes from '../../datastore/useComponentTypes';
import useAlert from '../../hooks/useAlert';
import { OrderItemViewModel } from '../../viewModels/OrderItemViewModel';
import StyledButton from '../button/StyledButton';

type AddItemToOrderModalProps = {
  item: OrderItem;
  onClose: (item: OrderItem | null) => void;
  submitText?: string;
};

export default function OrderItemVariationsModal({
  item,
  onClose,
  submitText = 'Add to cart',
}: AddItemToOrderModalProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { beansType, milkType, shotsType, sugarsType, syrupsType } = useComponentTypes()
  const { showAlert } = useAlert()
  const [vm, setViewModel] = useState<OrderItemViewModel>(
    new OrderItemViewModel(item, beansType, milkType, shotsType, sugarsType, syrupsType),
  );

  const addToCart = () => onClose(vm.toItem());

  const addVariation = (variationId: number | string) => {
    const tmp = vm.addVariant(variationId);
    setViewModel(tmp);
  };

  const removeVariation = (variationId: number | string | undefined) => {
    const tmp = vm.removeVariant(variationId);
    setViewModel(tmp);
  };

  const setNotes = (notes: string) => {
    const tmp = vm.setNotes(notes);
    setViewModel(tmp);
  };

  const setSize = (sizeId: string | number) => {
    const tmp = vm.setSize(sizeId);
    setViewModel(tmp);
  };

  const addSugar = (event: SelectChangeEvent<string>) => {
    const selectedVariationId = event.target.value;
    if (selectedVariationId === OrderItemViewModel.SELECT_SUGAR) {
      return
    }
    addVariation(selectedVariationId);
  };

  const addSyrup = (event: SelectChangeEvent<string>) => {
    const selectedVariationId = event.target.value;
    if (selectedVariationId === OrderItemViewModel.SELECT_SYRUP) {
      return
    }
    addVariation(selectedVariationId);
  };

  const handleDecafSwitch = () => {
    if (vm.hasDecafOption) {
      const decafBean = vm.beansVariations.find(f => f.name.toLowerCase().includes('decaf'))
      const beanToAdd = !vm.isDecafSelected && decafBean ? decafBean : vm.beansVariations.find(f => f.id !== decafBean?.id)
      if (beanToAdd) {
        addVariation(beanToAdd.id)
        return
      }
      console.error(`There is something wrong with ${item.productName}: cant toggle decaf. check all beans variants are added correctly`)
      showAlert('Cant switch decaf option', 'error')
    }
  }

  if (
    vm.selectedBean &&
    vm.allowsBeansVariations &&
    !vm.hasSelectedVariant(vm.selectedBean)
  ) {
    addVariation(vm.selectedBean.id);
  }

  const basePrice = vm.selectedSize?.basePrice || 0;

  const variantsThatCantBeIncremented = [beansType?.id, milkType?.id].filter(Boolean)

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
      onClose={() => onClose(null)}
      scroll={isMobile ? 'paper' : 'body'}
      PaperProps={{
        sx: {
          maxHeight: isMobile ? 'calc(100% - 64px)' : 'none',
        },
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" fontWeight="bold">
            {item.productName}
          </Typography>
          <IconButton onClick={() => onClose(null)}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers={isMobile}>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            {vm.allowsSizeVariations && (
              <FormControl fullWidth>
                <InputLabel>Size</InputLabel>
                <Select
                  value={vm.selectedSize.id}
                  size="small"
                  label="Size"
                  onChange={(e) => setSize(e.target.value)}
                >
                  {item.sizeOptions
                    .filter((sizeOption) => sizeOption.isActive)
                    .map((sizeOption) => (
                      <MenuItem value={sizeOption.id} key={sizeOption.id}>
                        {sizeOption.sizeName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {vm.showBeansVariations && vm.hasDecafOption && (
              <Box sx={{ flex: 1, marginLeft: 4 }}>
                <FormControlLabel
                  sx={{ justifyContent: 'flex-end', margin: 0 }}
                  control={
                    <Switch
                      checked={!!vm.isDecafSelected}
                      onChange={handleDecafSwitch}
                    />
                  } label="Decaf"
                />
              </Box>
            )}
          </Stack>
          {vm.allowsMilkVariations && (
            <FormControl fullWidth>
              <InputLabel>Milk</InputLabel>
              <Select
                value={vm.selectedMilk?.id || OrderItemViewModel.NO_MILK}
                label="Milk"
                size="small"
                onChange={(e) =>
                  addVariation(
                    e.target.value === OrderItemViewModel.NO_MILK
                      ? e.target.value
                      : +e.target.value,
                  )
                }
              >
                <MenuItem value={OrderItemViewModel.NO_MILK}>
                  {OrderItemViewModel.NO_MILK}
                </MenuItem>
                {vm.milkVariations.map((variation) => (
                  <MenuItem value={variation.id} key={variation.id}>
                    {variation.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {vm.allowsShotsVariation && !!vm.selectedShot && (
            <TextField
              fullWidth
              label="Xtra Shot"
              size="small"
              slotProps={{
                htmlInput: {
                  style: { textAlign: 'center' },
                },
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => removeVariation(vm.selectedShot!.id)}
                        disabled={vm.shotCount === 0}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => addVariation(vm.selectedShot!.id)}
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
              value={vm.shotCount}
            />
          )}
          {vm.allowsSugarVariations && (
            <FormControl fullWidth>
              <InputLabel>Sugar</InputLabel>
              <Select
                value={vm.selectedSugar?.id.toString() || OrderItemViewModel.SELECT_SUGAR}
                label="Sugar"
                size="small"
                onChange={addSugar}
              >
                <MenuItem value={OrderItemViewModel.SELECT_SUGAR} disabled>
                  {OrderItemViewModel.SELECT_SUGAR}
                </MenuItem>
                {vm.sugarVariations.map((variation) => (
                  <MenuItem value={variation.id} key={variation.id}>
                    {variation.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {vm.allowsSyrupVariations && (
            <FormControl fullWidth>
              <InputLabel>Syrups</InputLabel>
              <Select
                value={OrderItemViewModel.SELECT_SYRUP}
                label="Syrups"
                size="small"
                onChange={addSyrup}
              >
                <MenuItem value={OrderItemViewModel.SELECT_SYRUP} disabled>
                  {OrderItemViewModel.SELECT_SYRUP}
                </MenuItem>
                {vm.syrupVariations.map((variation) => (
                  <MenuItem value={variation.id} key={variation.id}>
                    {variation.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            label="Notes"
            value={vm.notes || ''}
            size="small"
            onChange={(e) => setNotes(e.target.value)}
            slotProps={{
              htmlInput: { maxLength: 50 },
              inputLabel: { shrink: true },
            }}
            fullWidth
            margin="normal"
          />
          <Grid container>
            <Grid size={10}>
              <Typography fontWeight="bold">Base Price</Typography>
            </Grid>
            <Grid size={2}>
              <Typography
                fontWeight="bold"
                textAlign="right"
              >{`$${basePrice.toFixed(2)}`}</Typography>
            </Grid>
            {
              vm.allowsMilkVariations && !vm.selectedMilk && (
                <>
                  <Grid size={9}>
                    <Typography>{OrderItemViewModel.NO_MILK}</Typography>
                  </Grid>
                  <Grid size={3}>
                    <Typography textAlign="right">
                      {formatCurrency(0, true)}
                    </Typography>
                  </Grid>
                </>
              )
            }
            {Object.keys(vm.variationsWithQty).map((variantId, idx) => {
              const variantWithQty = vm.variationsWithQty[variantId];
              if (variantWithQty.isCore) {
                return false
              }
              const nameChunks = [variantWithQty.name];
              if (variantWithQty.quantity > 1) {
                nameChunks.push(`x ${variantWithQty.quantity}`);
              }
              const canBeIncremented = !variantsThatCantBeIncremented.includes(
                variantWithQty.component?.componentTypeId,
              )
              return (
                <Fragment key={`${variantId}_${idx}`}>
                  <Grid size={6}>
                    <Typography>{variantWithQty.name}</Typography>
                  </Grid>
                  <Grid size={3} sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Typography sx={{ textAlign: 'center' }}>
                      {canBeIncremented && (
                        <IconButton
                          onClick={() => removeVariation(variantWithQty.id)}
                          sx={{ padding: 0, marginRight: 1 }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      )}
                      {variantWithQty.quantity}
                      {canBeIncremented && (
                        <IconButton
                          onClick={() => addVariation(variantWithQty.id)}
                          sx={{ padding: 0, marginLeft: 1 }}
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    </Typography>
                  </Grid>
                  <Grid size={3}>
                    <Typography textAlign="right">
                      {calculatePriceDifferenceWithSign(
                        productVariantPriceAdjustment(
                          variantWithQty,
                          vm.selectedSize,
                        )
                          .times(variantWithQty.quantity)
                          .toFixed(2),
                      )}
                    </Typography>
                  </Grid>
                </Fragment>
              );
            })}
            <Grid size={8}>
              <Typography fontWeight="bold">Total</Typography>
            </Grid>
            <Grid size={4}>
              <Typography
                fontWeight="bold"
                textAlign="right"
              >{`$${vm.total.toFixed(2)}`}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <StyledButton variant="contained" fullWidth onClick={addToCart}>
          {submitText}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}
