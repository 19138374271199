import { TpgeComponentTypeEndpoint } from '../common/endpoints';
import { ServerResponse, TpgeComponent, TpgeComponentType } from '../common/types';
import { getRequest, patchRequest, postRequest, putRequest } from './requests';

export async function getComponentTypes(includeDeleted = false) {
  const queryParams = includeDeleted ? '?includeDeleted=true' : '';
  const result: ServerResponse<TpgeComponentType[]> = await getRequest(
    `${TpgeComponentTypeEndpoint}${queryParams}`,
  );
  return result;
}
export async function updateComponentType(obj: TpgeComponentType) {
  const result: ServerResponse<TpgeComponentType> = await putRequest(
    `${TpgeComponentTypeEndpoint}/${obj.id}`,
    obj,
  );
  return result;
}
export async function createComponentType(obj: TpgeComponentType) {
  const result: ServerResponse<TpgeComponentType> = await postRequest(
    TpgeComponentTypeEndpoint,
    obj,
  );
  return result;
}


export async function archive(id: number) {
  const result: ServerResponse<null> = await patchRequest( `${TpgeComponentTypeEndpoint}/${id}/archive`, null);
  return result
}

export async function activeComponentsFor(id: number) {
  const result: ServerResponse<TpgeComponent[]> = await getRequest(
    `${TpgeComponentTypeEndpoint}/${id}/active-components`,
  );
  return result
}

export default { getComponentTypes, updateComponentType, createComponentType, archive, activeComponentsFor };
