import { TpgeComponentEndpoint } from '../common/endpoints';
import { ProductVariant, ServerResponse, TpgeComponent } from '../common/types';
import { getRequest, patchRequest, postRequest, putRequest } from './requests';

export async function getComponents(includeDeleted = false) {
  const queryParams = includeDeleted ? '?includeDeleted=true' : '';
  const result: ServerResponse<TpgeComponent[]> = await getRequest(
    `${TpgeComponentEndpoint}${queryParams}`,
  );
  return result;
}
export async function updateComponent(obj: TpgeComponent) {
  const result: ServerResponse<TpgeComponent> = await putRequest(
    `${TpgeComponentEndpoint}/${obj.id}`,
    obj,
  );
  return result;
}
export async function createComponent(obj: TpgeComponent) {
  const result: ServerResponse<TpgeComponent> = await postRequest(
    TpgeComponentEndpoint,
    obj,
  );
  return result;
}

export async function archive(id: number) {
  const result: ServerResponse<null> = await patchRequest( `${TpgeComponentEndpoint}/${id}/archive`, null);
  return result
}

export async function activeProductVariantsFor(id: number) {
  const result: ServerResponse<ProductVariant[]> = await getRequest(
    `${TpgeComponentEndpoint}/${id}/active-product-variants`,
  );
  return result
}

export default { getComponents, updateComponent, createComponent, archive, activeProductVariantsFor };
