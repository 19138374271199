import {
  calculatePriceDifferenceWithSign,
  productVariantPriceAdjustment,
} from '../../../common/moneyFunctions';
import {
  getOrderItemKey,
  groupOrderItems,
} from '../../../common/orderFunctions';
import {
  CustomerOrder,
  Order,
  OrderItem,
  OrderItemWithQty,
  ProductSize,
  ProductVariantWithQty,
} from '../../../common/types';

export function increaseQuantity(
  item: OrderItem,
  orderItems: OrderItem[] = [],
): OrderItem[] {
  const tempItems = [...orderItems];
  const itemKey = getOrderItemKey(item);
  const firstExample = orderItems.find(
    (orderItem: OrderItem) => getOrderItemKey(orderItem) === itemKey,
  );
  if (firstExample) {
    tempItems.push({ ...firstExample });
  }
  return tempItems;
}

export function decreaseQuantity(
  item: OrderItem,
  orderItems: OrderItem[] = [],
): OrderItem[] {
  const tempItems = [...orderItems];
  const itemKey = getOrderItemKey(item);
  const firstIndex = orderItems.findIndex(
    (orderItem: OrderItem) => getOrderItemKey(orderItem) === itemKey,
  );
  if (firstIndex > -1) {
    tempItems.splice(firstIndex, 1);
  }
  return tempItems;
}

export function summarizeOrderItems(items: OrderItem[]): OrderItemWithQty[] {
  // Group items by their selected variants
  const groupedItems = groupOrderItems(items);

  // Convert grouped items to an array
  return Object.values(groupedItems).sort((a, b) =>
    getOrderItemKey(a).localeCompare(getOrderItemKey(b)),
  );
}

const orderItemProductSize = (item: OrderItem): ProductSize | undefined => {
  return item.sizeOptions.find((size) => size.id === item.productSizeId);
};

export function productNameWithSize(item: OrderItem): string {
  const sizeVariant = orderItemProductSize(item);
  if (sizeVariant) {
    return `${sizeVariant.sizeName} ${item.productName}`;
  }
  return item.productName;
}

export function productVariantsWithQty(item: OrderItemWithQty, milkComponentTypeId = 0): string {
  const variants = [...item.selectedVariants];
  const productSize = orderItemProductSize(item);

  if (!productSize) {
    return '';
  }

  const hasMilkOptions = milkComponentTypeId && item.allowedVariants.find(f => f.component?.componentTypeId === milkComponentTypeId)
  if (!variants.length){
    return hasMilkOptions ? 'No Milk' : ''
  }

  const groupdedVariants: ProductVariantWithQty[] = Object.values(
    variants.reduce(
      (acc, item) => {
        const key = item.id;
        if (!acc[key]) {
          acc[key] = { ...item, quantity: 0 };
        }
        acc[key].quantity += 1;
        return acc;
      },
      {} as Record<string, ProductVariantWithQty>,
    ),
  );

  const out = groupdedVariants
    .filter(f => !f.isCore)
    .map((variant) => {
      const cost = productVariantPriceAdjustment(variant, productSize)
        .times(variant.quantity)
        .toNumber();
      let outStr = `${variant.name} (${calculatePriceDifferenceWithSign(cost)})`;
      if (variant.quantity > 1) {
        outStr = `${variant.quantity}x ${outStr}`;
      }
      return outStr;
    })
    .join(', ');
  if (hasMilkOptions && !item.selectedVariants.find(f => f.component?.componentTypeId === milkComponentTypeId)) {
    return out.length ? ['No Milk', out].join(', ') : 'No Milk'
  }
  return out;
}

export function orderToCustomerOrder(order: Partial<Order>): CustomerOrder {
  const customerOrder: CustomerOrder = {
    id: order.id ?? undefined,
    requestedPickupTime: order.requestedPickupTime!,
    customerId: order.customerId ?? undefined,
    siteId: order.siteId!,
    orderItems:
      order.orderItems?.map((item) => ({
        productId: item.productId,
        productSizeId: item.productSizeId,
        notes: item.notes,
        selectedVariants: item.selectedVariants.map((variant) => ({
          id: variant.id,
        })),
      })) ?? [],
  };
  return customerOrder;
}
