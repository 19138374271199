import { useState } from 'react';
import { ReportTimePeriods, ReportView } from '../../../../common/enums';
import { startOfDayWA } from '../../../../common/timeFunctions';

const useReportHeader = () => {
    const [date, _setDate] = useState<Date>(startOfDayWA(new Date()));
    const [comparisonPeriods, _setComparisonPeriods] = useState<number>(0);
    const [timePeriod, setTimePeriod] = useState<ReportTimePeriods>(
        ReportTimePeriods.Daily,
    );
    const [view, setView] = useState<ReportView.Chart | ReportView.Table>(
        ReportView.Chart,
    );

    const setDate = (date: Date) => {
        _setDate(date)
    }

    const clear = () => {
        setDate(new Date())
        setTimePeriod(ReportTimePeriods.Daily)
        setComparisonPeriods(0)
    }
    const handleViewChange = (
        _: React.MouseEvent<HTMLElement>,
        newView: ReportView.Chart | ReportView.Table | null,
    ): void => {
        if (newView !== null) {
            setView(newView);
        }
    }

    const setComparisonPeriods = (val: string | number) => {
        const intVal = parseInt(`${val}`);
        if (!isNaN(intVal)) {
            _setComparisonPeriods(intVal);
        } else if (intVal < 0) {
            _setComparisonPeriods(0);
        }
    }

    return {
        date,
        setDate,
        timePeriod,
        setTimePeriod,
        comparisonPeriods,
        setComparisonPeriods,
        view,
        handleViewChange,
        clear,
    };
}

export default useReportHeader