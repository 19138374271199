import { useEffect, useRef } from 'react';
import { playFindMySoundAsync } from '../common/alarmFunctions';

export const usePlayAlarmSound = (shouldPlay: boolean, delayInMilliseconds = 10000) => {
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    console.info('usePlayAlarmSound', {shouldPlay}, intervalRef.current);
    useEffect(() => {
        let isPlaying = false
        if (shouldPlay) {
            const play = async () => {
                if (isPlaying) {
                    console.log('already playing')
                    return
                }
                isPlaying = true
                await playFindMySoundAsync();
                isPlaying = false
            };

            // Play immediately and then set up the interval
            play();
            intervalRef.current = setInterval(() => {
                play();
            }, delayInMilliseconds);
        } else {
            // Clear the interval if shouldPlay is false
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        }

        return () => {
            isPlaying = false
            // Clean up on unmount
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [shouldPlay, delayInMilliseconds]);
};

export default usePlayAlarmSound