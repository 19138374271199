import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Decimal from 'decimal.js';
import reportsApi from '../../../../api/report';
import { ReportView } from '../../../../common/enums.tsx';
import { formatCurrency } from '../../../../common/moneyFunctions.tsx';
import { formatDateForReport } from '../../../../common/timeFunctions.tsx';
import { ReportDataPoint } from '../../../../common/types.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import useRequest from '../../../../hooks/useRequest.tsx';
import { getTotalsRow, padDataPoints } from './chartFunctions.tsx';
import ReportHeader from './ReportHeader.tsx';
import StyledTableContainer from './StyledTableContainer.tsx';
import TpgeStackedBarChart from './TpgeStackedBarChart.tsx';
import useReportHeader from './useReportHeader.tsx';

export interface RevenueCostProfitChartData {
  name: string;
  value: number;
}

const groupData = (data: ReportDataPoint[], timePeriod: number): ReportDataPoint[] => {
  const grouped = data.reduce<ReportDataPoint[]>((out, curr) => {
    const date = new Date(curr.timestamp);
    const key = formatDateForReport(date, timePeriod);
    const tmp: ReportDataPoint = {
      timestamp: key,
      'Revenue': new Decimal(curr.totalRevenue ?? 0).toFixed(2),
      'Cost': new Decimal(curr.totalCost ?? 0).times(-1).toFixed(2),
      'Profit': new Decimal(curr.totalProfit ?? 0).toFixed(2),
    }
    return [...out, tmp]
  }, [])
  return Object.values(grouped)
}
const barKeys = ['Revenue', 'Cost', 'Profit']
export default function RevenueCostProfitReport() {
  const { date, setDate, timePeriod, setTimePeriod, comparisonPeriods, setComparisonPeriods, view, handleViewChange, clear } = useReportHeader()
  const { data, loading: isLoading } = useRequest(() => reportsApi.revenueCostProfit(date, timePeriod, comparisonPeriods), [date, timePeriod, comparisonPeriods])

  const grouped: ReportDataPoint[] = groupData(data ?? [], timePeriod)
  const reportData = padDataPoints(grouped ?? [], timePeriod, date, comparisonPeriods)

  const totals = getTotalsRow(grouped, barKeys, 2)

  return (
    <>
      <ReportHeader
        date={date}
        setDate={setDate}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
        comparisonPeriods={comparisonPeriods}
        setComparisonPeriods={setComparisonPeriods}
        view={view}
        handleViewChange={handleViewChange}
        clear={clear}
      />
      <LoadingSpinner isLoading={isLoading}>
        <>
          {reportData && (
            <>
              {view === ReportView.Chart ? (
                <TpgeStackedBarChart
                  data={reportData}
                  barKeys={barKeys}
                  timePeriod={timePeriod}
                  showNegativeXaxis
                />
              ) : (
                <StyledTableContainer>
                  <Table size="medium" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date/Time</TableCell>
                        <TableCell align="right">Revenue</TableCell>
                        <TableCell align="right">Cost</TableCell>
                        <TableCell align="right">Profit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        grouped.map(datapoint => (
                          <TableRow key={datapoint.timestamp}>
                            <TableCell>{datapoint.timestamp}</TableCell>
                            <TableCell align="right">{formatCurrency(datapoint.Revenue ?? 0, true)}</TableCell>
                            <TableCell align="right">{formatCurrency(datapoint.Cost ?? 0, true)}</TableCell>
                            <TableCell align="right">{formatCurrency(datapoint.Profit ?? 0, true)}</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>Total</TableCell>
                      <TableCell align="right">{formatCurrency(totals.Revenue ?? 0, true)}</TableCell>
                      <TableCell align="right">{formatCurrency(totals.Cost ?? 0, true)}</TableCell>
                      <TableCell align="right">{formatCurrency(totals.Profit ?? 0, true)}</TableCell>
                    </TableRow>
                  </Table>
                </StyledTableContainer>
              )}
            </>
          )}
        </>
      </LoadingSpinner>
    </>
  );
}
