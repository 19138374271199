/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ProductCategoryType } from '../../../../common/enums';
import { sortOrdersById } from '../../../../common/sortFunctions';
import { StaffOrder } from '../../../../common/types';
import InfoCard from '../../../../components/card/InfoCard';
import useAlert from '../../../../hooks/useAlert';
import {
  getOrderPosition,
  handleFinishOrder,
  handleStartStopOrder,
  shiftOrderItems,
} from '../../functions/orderFunctions';
import {
  allColdDrinksPlaced,
  allColdDrinksStarted
} from '../../functions/stationFunctions';
import DraggableDrinkOrderCard from './DraggableDrinkOrderCard';
import StationHeader from './StationHeader';

interface ColdDrinkStationProps {
  orders: StaffOrder[];
}

export default function ColdDrinksStation({
  orders,
  children
}: PropsWithChildren<ColdDrinkStationProps>) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useAlert();
  const [draggableOrders, setDraggableOrders] = useState<StaffOrder[]>(orders);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  })
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  })
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  })
  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    keyboardSensor,
  );

  useEffect(() => {
    const incomingOrdersAreDifferent = !isEqual(
      sortOrdersById(orders),
      sortOrdersById(draggableOrders),
    );
    if (incomingOrdersAreDifferent) {
      const updatedOrders = orders.map((order) => {
        const updatedItems = order.orderItems.map((orderItem) => {
          const foundOrder = draggableOrders.find(
            (dragOrder) => order.id === dragOrder.id,
          );
          const foundItem = foundOrder?.orderItems.find(
            (item) => item.id === orderItem.id,
          );

          return {
            ...orderItem,
            queueOrder: foundItem?.queueOrder ?? orderItem.queueOrder,
          };
        });

        return {
          ...order,
          orderItems: updatedItems,
        };
      });

      setDraggableOrders(updatedOrders);
      setIsLoading(false);
    }
  }, [orders]);

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id === over.id) return;

    setDraggableOrders((prevOrders) => {
      const originalPosition = getOrderPosition(active.id, draggableOrders);
      const newPosition = getOrderPosition(over.id, draggableOrders);
      const newArray = arrayMove(prevOrders, originalPosition, newPosition);
      shiftOrderItems(newArray, [ProductCategoryType.ColdDrinks], showAlert);
      return newArray;
    });
  }

  const sortedFilteredOrders = draggableOrders.map((order) => {
    const isAllColdDrinksPlaced = allColdDrinksPlaced(order.orderItems);
    const isAllColdDrinksStarted = allColdDrinksStarted(order.orderItems);
    return {
      id: order.id,
      order,
      isAllColdDrinksPlaced,
      isAllColdDrinksStarted,
    };
  });

  return (
    <Grid
      size={12}
      sx={{
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StationHeader />
      <Divider />
      <Box
        sx={{
          overflowY: 'auto',
          height: 'calc(100vh - 64px)',
        }}
      >
        {children}
        {sortedFilteredOrders.length === 0 ? (
            <InfoCard
              Icon={NotificationsIcon}
              title="No Results Found"
              subtitle="Try refreshing your page or check back later."
            />
        ) : (
          <Grid container spacing={1} sx={{ padding: 3 }}>
            <DndContext
              collisionDetection={closestCorners}
              sensors={sensors}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext
                items={draggableOrders as any} //need this to be any as dnd-kit is not typed.
                strategy={verticalListSortingStrategy}
              >
                {sortedFilteredOrders.map(
                  (
                    {
                      order,
                      isAllColdDrinksPlaced,
                      isAllColdDrinksStarted,
                    }
                  ) => {
                    return (
                      <Grid size={12} key={order.id}>
                        <Paper
                          elevation={8}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            padding: 0,
                            margin: 0,
                            height: '100%',
                            backgroundColor: theme.palette.common.white,
                            color: theme.palette.common.deepblue,
                          }}
                        >
                          <DraggableDrinkOrderCard
                            order={order}
                            isAllDrinksPlaced={isAllColdDrinksPlaced}
                            isAllDrinksStarted={isAllColdDrinksStarted}
                            isColdDrinks={true}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            handleStartOrder={() => {
                              handleStartStopOrder(
                                order,
                                true,
                                [ProductCategoryType.ColdDrinks],
                                showAlert,
                                setIsLoading,
                              );
                            }}
                            handleStopOrder={() => {
                              handleStartStopOrder(
                                order,
                                false,
                                [ProductCategoryType.ColdDrinks],
                                showAlert,
                                setIsLoading,
                              );
                            }}
                            handleFinishOrder={() =>
                              handleFinishOrder(
                                order,
                                [ProductCategoryType.ColdDrinks],
                                showAlert,
                                setIsLoading,
                              )
                            }
                          />
                        </Paper>
                      </Grid>
                    );
                  },
                )}
              </SortableContext>
            </DndContext>
          </Grid>
        )}
      </Box>
    </Grid>
  );
}
