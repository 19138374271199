import { create } from 'zustand';
import { isAfter } from 'date-fns/isAfter';
import sitesApi from '../api/site';
import { Site } from '../common/types';
import { OrderingAvailabilityStatus } from '../common/enums';
import { latestPickupTime } from '../common/timeFunctions';

type SitesStore = {
  hasLoaded: boolean;
  sites: Site[];
  isAcceptingOnlineOrders: (id: number | null) => boolean;
  getSiteOrderingAvailabilityStatus: (id: number | null) => OrderingAvailabilityStatus;
  loading: boolean;
  error: unknown | null;
  fetchSites: () => void;
};

const useSites = create<SitesStore>((set, get) => ({
  hasLoaded: false,
  sites: [],
  loading: false,
  error: null,
  fetchSites: async () => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true });
      const result = await sitesApi.listSites();
      set({ sites: result.data ?? [], loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
  isAcceptingOnlineOrders: (id: number | null) => {
    if (id === null) {
      return true
    }
    const site = get().sites.find((s) => s.id === id);
    return site?.isAcceptingOnlineOrders ?? false;
  },
  getSiteOrderingAvailabilityStatus: (id: number | null): OrderingAvailabilityStatus => {
    if (id === null) {
      return OrderingAvailabilityStatus.Today
    }
    const site = get().sites.find((s) => s.id === id)
    if (!site) {
      // block it on the server, but seriously how did this happen?
      return OrderingAvailabilityStatus.Today
    }
    if (!site.isAcceptingOnlineOrders) {
      return OrderingAvailabilityStatus.Unavailable
    }
    const now = new Date()
    if (isAfter(now, latestPickupTime(now))) {
      return OrderingAvailabilityStatus.Tomorrow
    }
    return OrderingAvailabilityStatus.Today
  }
}));

export default useSites;

// make that first call without having to useEffect in a component
if (!useSites.getState().hasLoaded) {
  useSites.getState().fetchSites();
}
