import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import StyledSwitch from '../../../../components/switch/StyledSwitch';

interface PosHeaderProps {
  searchCustomer: string;
  setSearchCustomer: (value: string) => void;
  hidePaid: boolean;
  setHidePaid: (value: boolean) => void;
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
  resetFilters: () => void;
}

export default function PosHeader({
  searchCustomer,
  setSearchCustomer,
  hidePaid,
  setHidePaid,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  resetFilters
}: PosHeaderProps) {
  const theme = useTheme();

  return (
    <Stack spacing={2} sx={{ margin: 1, paddingRight: 2 }}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack sx={{ flexDirection: 'row' }}>
          <PointOfSaleIcon fontSize="large" />
          <Typography variant="h2" fontWeight="600" sx={{ paddingLeft: 1 }}>
            Point of Sale
          </Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          sx={{ marginLeft: 'auto' }}
        >
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={onStartDateChange}
              slotProps={{
                textField: { size: 'small' },
              }}
              format="dd/MM/yyyy"
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={onEndDateChange}
              slotProps={{
                textField: { size: 'small' },
              }}
              format="dd/MM/yyyy"
            />
          </Stack>

          <TextField
            size="small"
            sx={{ width: 300 }}
            label="Search Customer Name"
            value={searchCustomer}
            onChange={(e) => setSearchCustomer(e.target.value)}
          />

          <StyledSwitch
            value={hidePaid}
            onChange={setHidePaid}
            labelColor={theme.palette.common.deepblue}
            label="Hide Paid"
          />

          <Button variant="text" onClick={resetFilters}>Reset</Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
