import AssessmentIcon from '@mui/icons-material/Assessment';
import { useState } from 'react';
import { ReportTypes } from '../../../common/enums.tsx';
import { hasAdminRole } from '../../../common/roleFunctions.tsx';
import useAPI from '../../../hooks/useAPI.tsx';
import { AdminHeader } from '../components/AdminHeader.tsx';
import AdminPageContent from '../components/AdminPageContent.tsx';
import ComponentConsumptionReport from './components/ComponentConsumption.tsx';
import ProductsSoldReport from './components/ProductsSold.tsx';
import RevenueCostProfitReport from './components/RevenueCostProfit.tsx';
import TotalSalesReport from './components/TotalSalesReport.tsx';
import AverageSpend from './components/AverageSpend.tsx';

const dropDownOptions = [
  { value: ReportTypes.TotalSales, label: 'Total Sales' },
  { value: ReportTypes.ComponentConsumption, label: 'Component Consumption' },
  { value: ReportTypes.RevenueBreakdown, label: 'Revenue Breakdown' },
  { value: ReportTypes.ProductsSold, label: 'Products Sold' },
  { value: ReportTypes.AverageSpend, label: 'Average Spend' },
];

export default function ReportsPage() {
  const { currentUser } = useAPI();
  const [selectedReport, setSelectedReport] = useState<string>(ReportTypes.TotalSales);

  if (!hasAdminRole(currentUser)) return null;

  function renderReport(value: string) {
    switch (value) {
      case ReportTypes.TotalSales:
        return <TotalSalesReport />;
      case ReportTypes.ComponentConsumption:
        return <ComponentConsumptionReport />;
      case ReportTypes.RevenueBreakdown:
        return <RevenueCostProfitReport />;
      case ReportTypes.ProductsSold:
        return <ProductsSoldReport />;
      case ReportTypes.AverageSpend:
        return <AverageSpend />;
      default:
        return null;
    }
  }

  return (
    <>
      <AdminHeader
        title="Reports"
        description="Choose from a range of reports to analyse."
        icon={<AssessmentIcon fontSize="large" sx={{ color: 'white' }} />}
        dropdownOptions={dropDownOptions}
        selectedDropdownValue={selectedReport}
        onDropdownChange={(value) => setSelectedReport(value)}
      />

      <AdminPageContent>{renderReport(selectedReport)}</AdminPageContent>
    </>
  );
}
