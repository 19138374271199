import Box from '@mui/material/Box'
import { ReactElement } from 'react'
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { formatCurrency } from '../../../../common/moneyFunctions'
import { ReportDataPoint } from '../../../../common/types'
import { barLabel, getColor, renderPeriodTick } from './chartFunctions'

interface Props {
    data: ReportDataPoint[]
    timePeriod: number
    barKeys: string[]
    customToolTip?: () => ReactElement
    showNegativeXaxis?: boolean
}
export default function TpgeStackedBarChart({ data, timePeriod, barKeys = [], customToolTip = undefined, showNegativeXaxis = false }: Props) {
    return (
        <Box sx={{ width: '100%', height: '60vh', minHeight: '400px' }}>
            <ResponsiveContainer>
                <BarChart
                    data={data}
                    stackOffset={showNegativeXaxis ? 'sign' : 'none'}
                    margin={{
                        top: 20,
                        right: 60,
                        left: 60,
                        bottom: 20,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="timestamp"
                        interval={'preserveEnd'}
                        tickFormatter={(val) => barLabel(val, timePeriod)}
                    />
                    <XAxis
                        dataKey="timestamp"
                        axisLine={false}
                        tickLine={false}
                        interval={0}
                        tick={(props) => renderPeriodTick(props, timePeriod)}
                        height={1}
                        scale="band"
                        xAxisId="section"
                    />
                    <YAxis />
                    {
                        customToolTip ? customToolTip() : (
                            <Tooltip
                                formatter={(value) => formatCurrency(`${value}`, true)}
                            />
                        )
                    }
                    <Legend verticalAlign='top' />
                    {
                        showNegativeXaxis && <ReferenceLine y={0} stroke="#000" />
                    }
                    {
                        barKeys.map((key, idx) => <Bar key={key} dataKey={key} stackId="a" fill={getColor(idx)} />)
                    }
                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}