import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';
import { Tooltip } from 'recharts';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import reports from '../../../../api/report';
import { ReportView } from '../../../../common/enums.tsx';
import { ReportDataPoint, TpgeComponentType } from '../../../../common/types.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import useComponentTypes from '../../../../datastore/useComponentTypes.tsx';
import useAlert from '../../../../hooks/useAlert';
import useRequest from '../../../../hooks/useRequest';
import { formatDateForReport } from '../../../../common/timeFunctions.tsx';
import ReportHeader from './ReportHeader.tsx';
import StyledTableContainer from './StyledTableContainer.tsx';
import TpgeStackedBarChart from './TpgeStackedBarChart.tsx';
import { barLabel, getTotalsRow, padDataPoints, parseCustomDate } from './chartFunctions.tsx';
import useReportHeader from './useReportHeader.tsx';



const groupByComponentType = (data: ReportDataPoint[], timePeriod: number): ReportDataPoint[] => {
  const groupedByTypeData = (data ?? []).reduce<ReportDataPoint[]>((out, curr) => {
    if (!curr.componentTypeName) {
      return out
    }
    let datapoint = out.find(f => f.timestamp === curr.timestamp);
    if (!datapoint) {
      datapoint = { timestamp: curr.timestamp }
      out.push(datapoint)
    }

    const quantityKey = curr.componentTypeName;
    const unitsKey = `${quantityKey}_units`;
    if (!datapoint[quantityKey]) {
      datapoint[quantityKey] = '0'
      datapoint[unitsKey] = curr.componentUnits
    }
    datapoint[quantityKey] = new Decimal(datapoint[quantityKey]).plus(new Decimal(curr.componentQuantitySum ?? 0)).toFixed(2)
    return out
  }, []);

  return Object.values(groupedByTypeData).map(m => ({ ...m, timestamp: formatDateForReport(parseCustomDate(m.timestamp), timePeriod) }))
}

const groupByComponent = (data: ReportDataPoint[], componentType: TpgeComponentType | null, timePeriod: number): ReportDataPoint[] => {
  if (!componentType) {
    return []
  }
  const groupedByTimestamp = data.reduce<Record<string, ReportDataPoint>>((acc, item) => {
    if (item.componentTypeName !== componentType.name) {
      return acc
    }
    if (!acc[item.timestamp]) {
      acc[item.timestamp] = { timestamp: item.timestamp };
    }

    const group = acc[item.timestamp];
    const quantityKey = item.componentName;
    const unitsKey = `${quantityKey}_units`;

    if (!group[quantityKey]) {
      group[quantityKey] = '0'
      group[unitsKey] = item.componentUnits
    }
    group[quantityKey] = new Decimal(group[quantityKey]).plus(new Decimal(item.componentQuantitySum ?? 0)).toFixed(2)

    return acc;
  }, {});

  return Object.values(groupedByTimestamp).map(m => ({ ...m, timestamp: formatDateForReport(parseCustomDate(m.timestamp), timePeriod) }))
}

export default function ComponentConsumptionReport() {
  const { componentTypes = [] } = useComponentTypes();
  const { date, setDate, timePeriod, setTimePeriod, comparisonPeriods, setComparisonPeriods, view, handleViewChange, clear } = useReportHeader()
  const [tpgeComponentType, setTpgeComponentType] =
    useState<TpgeComponentType | null>(null);
  const {
    data,
    error,
    loading: isLoading,
  } = useRequest(
    () =>
      reports.componentConsumption(
        date,
        timePeriod,
        comparisonPeriods
      ),
    [date, timePeriod, comparisonPeriods],
  );
  useEffect(() => {
    if (error) {
      console.error(error);
      showAlert('Failed to get report, please refresh and try again.', 'error');
    }
  }, [error]);
  const { showAlert } = useAlert();

  const selectedComponentTypeId = tpgeComponentType?.id || 0;
  const byComponent = !!selectedComponentTypeId
  const componentTypeGroup = groupByComponentType(data ?? [], timePeriod)
  const componentGroup = groupByComponent(data ?? [], tpgeComponentType, timePeriod)
  const componentGroupKeys = componentGroup.flatMap(d => Object.keys(d)
    .filter(f => f !== 'timestamp' && f.indexOf('_units') === -1))
    .filter((el, i, arr) => arr.indexOf(el) === i)
  const grouped = byComponent ? componentGroup : componentTypeGroup
  const reportData = padDataPoints(grouped ?? [], timePeriod, date, comparisonPeriods)
  const barKeys = byComponent ? componentGroupKeys : componentTypes.map(x => x.name).sort((a, b) => a.localeCompare(b))

  const totals = getTotalsRow(grouped, barKeys, 2, true)

  return (
    <>
      <ReportHeader
        date={date}
        setDate={setDate}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
        comparisonPeriods={comparisonPeriods}
        setComparisonPeriods={setComparisonPeriods}
        view={view}
        handleViewChange={handleViewChange}
        clear={() => {
          clear();
          setTpgeComponentType(null)
        }}
      >
        <TextField
          select
          size="small"
          value={selectedComponentTypeId}
          label="Component Type"
          sx={{ minWidth: 130 }}
          onChange={(e) =>
            setTpgeComponentType(
              componentTypes.find((c) => c.id === +e.target.value) || null,
            )
          }
        >
          <MenuItem value={0}>All</MenuItem>
          {componentTypes
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
        </TextField>
      </ReportHeader>

      <LoadingSpinner isLoading={isLoading}>
        {view === ReportView.Chart ? (
          <TpgeStackedBarChart
            data={reportData}
            barKeys={barKeys}
            timePeriod={timePeriod}
            customToolTip={() => (
              <Tooltip
                formatter={(value, name, item) => `${new Decimal(value.toString()).toFixed(2)} ${item.payload?.[`${name}_units`] ?? ''}`}
                labelFormatter={(label) => barLabel(label, timePeriod)}
              />
            )}
          />
        ) : (
          <StyledTableContainer maxWidth={'80%'}>
            <Table size="medium" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Date/Time</TableCell>
                  {
                    barKeys.map(m => <TableCell key={m} align="right">{m}</TableCell>)
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {grouped.map((row) => (
                  <TableRow key={row.type}>
                    <TableCell component="th" scope="row">
                      {row.timestamp}
                    </TableCell>
                    {
                      barKeys.map(key => <TableCell align="right" key={key}>{row[key]} {row[`${key}_units`]}</TableCell>)
                    }
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Total
                  </TableCell>
                  {
                    barKeys.map(key => <TableCell key={key} align="right">{totals[key]} {totals[`${key}_units`]}</TableCell>)
                  }
                </TableRow>
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </LoadingSpinner>

    </>
  );
}
