import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useSites from '../../../datastore/useSites';
import useSelectedSite from '../../../hooks/useSelectedSite';
import { hexWithAlpha } from '../../../theme/theme';
import { isInActiveWindow } from '../../../common/timeFunctions';

export default function CurrentWaitTime() {
  const theme = useTheme();
  const { sites } = useSites()
  const [estimatedWaitTimeInMinutes, selectedSiteId] = useSelectedSite(
    (state) => [state.estimatedWaitTimeInMinutes, state.selectedSiteId],
  );

  if (!selectedSiteId) {
    return null;
  }

  const site = sites.find((f) => f.id === selectedSiteId);
  if (!site || !site.isAcceptingOnlineOrders || !isInActiveWindow(new Date())) {
    return null;
  }

  return (
    <Stack
      flexDirection="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        padding: 1,
        backgroundColor: hexWithAlpha(theme.palette.common.babyBlue, 0.2),
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{ color: theme.palette.common.babyBlue }}
      >
        Current Wait Time: ~{estimatedWaitTimeInMinutes?.toFixed(2)} mins
      </Typography>
    </Stack>
  );
}
