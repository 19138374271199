import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Decimal from 'decimal.js';
import reportsApi from '../../../../api/report.tsx';
import { ReportView } from '../../../../common/enums.tsx';
import { formatDateForReport } from '../../../../common/timeFunctions.tsx';
import { ReportDataPoint } from '../../../../common/types.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import useRequest from '../../../../hooks/useRequest.tsx';
import { formatCurrency } from '../../../../common/moneyFunctions.tsx';
import ReportHeader from './ReportHeader.tsx';
import StyledTableContainer from './StyledTableContainer.tsx';
import TpgeStackedBarChart from './TpgeStackedBarChart.tsx';
import { padDataPoints, parseCustomDate } from './chartFunctions.tsx';
import useReportHeader from './useReportHeader.tsx';

export interface RevenueCostProfitChartData {
  name: string;
  value: number;
}

const groupData = (data: ReportDataPoint[], timePeriod: number): ReportDataPoint[] => {
  const grouped = data.reduce<ReportDataPoint[]>((out, curr) => {
    const tmp: ReportDataPoint = {
      timestamp: formatDateForReport(parseCustomDate(curr.timestamp), timePeriod),
      'Average Spend': new Decimal(curr.averageSpend ?? 0).toFixed(2)
    }
    return [...out, tmp]
  }, [])
  return Object.values(grouped)
}

const barKeys = ['Average Spend']

export default function AverageSpend() {
  const { date, setDate, timePeriod, setTimePeriod, comparisonPeriods, setComparisonPeriods, view, handleViewChange, clear } = useReportHeader()
  const { data, loading: isLoading } = useRequest(() => reportsApi.averageSpend(date, timePeriod, comparisonPeriods), [date, timePeriod, comparisonPeriods])

  const grouped = groupData(data ?? [], timePeriod)
  const reportData = padDataPoints(grouped, timePeriod, date, comparisonPeriods)

  return (
    <>
      <ReportHeader
        date={date}
        setDate={setDate}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
        comparisonPeriods={comparisonPeriods}
        setComparisonPeriods={setComparisonPeriods}
        view={view}
        handleViewChange={handleViewChange}
        clear={clear}
      />
      <LoadingSpinner isLoading={isLoading}>
        <>
          {reportData && (
            <>
              {view === ReportView.Chart ? (
                <TpgeStackedBarChart
                  data={reportData}
                  barKeys={barKeys}
                  timePeriod={timePeriod}
                />
              ) : (
                <StyledTableContainer>
                  <Table size="medium" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date/Time</TableCell>
                        <TableCell align="right">Average Spend</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        grouped.map(datapoint => (
                          <TableRow key={datapoint.timestamp}>
                            <TableCell>{datapoint.timestamp}</TableCell>
                            <TableCell align="right">{formatCurrency(datapoint?.['Average Spend'] ?? 0, true)}</TableCell>
                          </TableRow>

                        ))
                      }
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
            </>
          )}
        </>
      </LoadingSpinner>
    </>
  );
}
