import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import siteApi from '../../../api/site';
import staffApi from '../../../api/staff';
import LocalStorageSvc, {
  USER_DATA_KEY,
} from '../../../common/localStorageSvc';
import { isAdmin, isStaff } from '../../../common/typeAssertionFunctions';
import { Staff } from '../../../common/types';
import DarkUpperSection from '../../../components/section/DarkUpperSection';
import StyledSelect from '../../../components/select/StyledSelect';
import useSites from '../../../datastore/useSites';
import useStations from '../../../datastore/useStations';
import useAPI from '../../../hooks/useAPI';
import useAlert from '../../../hooks/useAlert';
import useLoadingModal from '../../../hooks/useLoadingModal';

export default function StaffSettings() {
  const { currentUser, setCurrentUser } = useAPI();

  const currentUserStationId = (currentUser as Staff)?.stationId ?? 0;
  const { stations = [], loading: stationsLoading } = useStations();
  const { sites = [], loading: sitesLoading, fetchSites } = useSites()
  const { loading, setLoading } = useLoadingModal()

  const theme = useTheme();
  const { showAlert } = useAlert();

  const selectedStation = (stations || []).find(f => f.id === currentUserStationId)
  const onlineOrderingPaused = sites.some((s) => !s.isAcceptingOnlineOrders);

  async function saveStation(id: number) {
    const newStation = stations.find((s) => s.id === id);
    if (!id || !newStation) {
      showAlert('Please select a station', 'error');
      return;
    }
    setLoading(true)
    try {
      if (isStaff(currentUser)) {
        const result = await staffApi.updateStation(
          currentUser?.email,
          id,
        );
        if (result.status === 200) {
          const updatedUser = {
            ...currentUser,
            stationId: id,
            stationName: newStation.name,
          };
          setCurrentUser(updatedUser);
          LocalStorageSvc.save(USER_DATA_KEY, updatedUser);
          showAlert('Station updated', 'success');
        } else {
          showAlert('Update station failed', 'error');
        }
      }
    } catch (error) {
      showAlert('Update station failed', 'error');
      console.error(error);
    } finally {
      setLoading(false)
    }
  }


  function updateStation(stationId: number) {
    if (isStaff(currentUser) && !!stationId) {
      const foundStation = stations.find((s) => s.id === stationId);
      if (!foundStation) {
        showAlert('Station not found', 'warning');
        return;
      }
      saveStation(stationId)
    }
  }

  async function toggleOnlineOrdering(currentState: boolean) {
    if (isStaff(currentUser) && isAdmin(currentUser)) {
      try {
        setLoading(true)
        const result = await siteApi.adminToggleOnlineOrdering(!currentState);
        if (result.status === 200) {
          showAlert('Online Ordering updated', 'success');
          await fetchSites()
        } else {
          showAlert('Update online ordering failed', 'error');
        }
      } catch (err) {
        console.error(err)
        showAlert('Update online ordering failed', 'error');
      } finally {
        setLoading(false)
      }
    }
  }

  if (!stations.length || !isStaff(currentUser)) return null;

  const orderedStations = [
    { value: 0, name: 'Choose Station', id: 0 },
    ...stations.sort((a, b) => a.name.localeCompare(b.name)),
  ];

  return (
    <Stack flex={1} sx={{ height: '100vh' }}>
      <DarkUpperSection>
        <Stack direction="row" spacing={4} sx={{ padding: '1em 0', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography
            variant="h2"
            fontWeight="bold"
            color={theme.palette.primary.contrastText}
            flex={1}
          >
            Settings
          </Typography>
        </Stack>
      </DarkUpperSection>
      <Stack flex={1} direction="column" marginX={2}>
        <Stack direction="row" spacing={4} sx={{ alignItems: 'center', paddingY: 2 }}>
          <Typography variant="h4">
            Set your Station:
          </Typography>
          <StyledSelect
            value={
              (selectedStation
                ? selectedStation.id
                : currentUser?.stationId) || 0
            }
            onChange={(e) => updateStation(e ? +e : 0)}
            disabled={loading || stationsLoading}
          >
            {orderedStations?.map((station) => (
              <MenuItem
                key={station.id}
                value={station.id}
                disabled={station.id === 0}
              >
                {station.name}
              </MenuItem>
            ))}
          </StyledSelect>

        </Stack>
        {isAdmin(currentUser) && (
          <>
            <Divider />
            <Stack direction="row" spacing={4} sx={{ alignItems: 'center', paddingY: 2 }}>
              <Typography variant="h4">
                {onlineOrderingPaused ? 'Online Ordering: Paused' : 'Online Ordering: Active'}
              </Typography>
              <Button
                variant="contained"
                color={onlineOrderingPaused ? 'success' : 'error'}
                onClick={() => toggleOnlineOrdering(onlineOrderingPaused)}
                disabled={loading || sitesLoading}
              >
                {onlineOrderingPaused ? 'Resume' : 'Pause'}
              </Button>
            </Stack>
          </>

        )}
      </Stack>
    </Stack>
  );
}
