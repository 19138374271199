import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TpgeComponent } from '../../../../common/types';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner';

interface ComponentsTableProps {
  isLoading: boolean;
  paginatedData: TpgeComponent[];
  showDisabled: boolean;
  editComponent: (component: TpgeComponent) => void;
  statusToggle: (component: TpgeComponent) => void;
  onArchiveClicked: (component: TpgeComponent) => void;
}

export function ComponentsTable({
  isLoading,
  paginatedData,
  showDisabled,
  editComponent,
  statusToggle,
  onArchiveClicked
}: ComponentsTableProps) {

  return (
    <Table sx={{ maxWidth: '100%', overflowX: 'auto' }} size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Default</TableCell>
          <TableCell>Cost per Unit</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <LoadingSpinner isLoading={isLoading} isTable colSpan={5}>
          {paginatedData.map((component) => {
            if (!component.isActive && !showDisabled) return null;
            return (
              <TableRow
                key={component.id}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    cursor: 'pointer',
                  },
                }}
              >
                <TableCell>{component.name}</TableCell>
                <TableCell>{component.componentType?.name}</TableCell>
                <TableCell>{component.isDefault ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  ${component.costPerUnit} per {component.unit}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ marginRight: 2 }}
                    onClick={() => editComponent(component)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ marginRight: 2 }}
                    onClick={() => statusToggle(component)}
                    color={component.isActive ? 'error' : 'primary'}
                  >
                    {component.isActive ? 'Disable' : 'Enable'}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => onArchiveClicked(component)}
                    color={'warning'}
                  >
                    Archive
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </LoadingSpinner>
      </TableBody>
    </Table>
  );
}
