import { SiteEndpoint } from '../common/endpoints';
import { ServerResponse, Site } from '../common/types';
import { getRequest, postRequest } from './requests';

export type EstimatedWaitTime = {
  estimatedWaitTimeInMinutes: number;
};

export async function listSites() {
  const result: ServerResponse<Site[]> = await getRequest(`${SiteEndpoint}`);
  return result;
}

export async function getEstimatedWaitTime(siteId: number) {
  const result: ServerResponse<EstimatedWaitTime> = await getRequest(
    `${SiteEndpoint}/${siteId}/estimated-wait-time`,
  );
  return result;
}
export async function adminToggleOnlineOrdering(isPaused: boolean) {
  const result: ServerResponse<null> = await postRequest(
    `${SiteEndpoint}/pause-online-ordering`,
    { isPaused }
  )
  return result
}

export default { listSites, getEstimatedWaitTime, adminToggleOnlineOrdering };
