import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Decimal from 'decimal.js';
import { useState } from 'react';
import TableBody from '@mui/material/TableBody';
import reportsApi from '../../../../api/report';
import { ReportView } from '../../../../common/enums.tsx';
import { formatDateForReport } from '../../../../common/timeFunctions.tsx';
import { ProductCategory, ReportDataPoint } from '../../../../common/types.tsx';
import LoadingSpinner from '../../../../components/loadingSpinner/LoadingSpinner.tsx';
import useProductCategories from '../../../../datastore/useProductCategories.tsx';
import useRequest from '../../../../hooks/useRequest.tsx';
import { barLabel, getTotalsRow, padDataPoints, parseCustomDate } from './chartFunctions.tsx';
import ReportHeader from './ReportHeader.tsx';
import StyledTableContainer from './StyledTableContainer.tsx';
import TpgeStackedBarChart from './TpgeStackedBarChart.tsx';
import useReportHeader from './useReportHeader.tsx';
import { Tooltip } from 'recharts';

export interface TransformedDataItem {
  name: string;
  fullName: string;
  [categoryName: string]: string | number;
}

const groupByCategory = (data: ReportDataPoint[], timePeriod: number): ReportDataPoint[] => {
  const grouped = data.reduce<ReportDataPoint[]>((out, curr) => {
    let datapoint = out.find(f => f.timestamp === curr.timestamp)
    if (!datapoint) {
      datapoint = { timestamp: curr.timestamp }
      out.push(datapoint)
    }
    const categoryKey = curr.productCategoryName
    if (!datapoint[categoryKey]) {
      datapoint[categoryKey] = '0'
    }
    datapoint[categoryKey] = new Decimal(datapoint[categoryKey] ?? 0).plus(curr?.totalProducts ?? 0).toFixed(0)
    return out
  }, [])
  return grouped.map(m => ({ ...m, timestamp: formatDateForReport(parseCustomDate(m.timestamp), timePeriod) }))
}

const groupByCategoryAndProduct = (data: ReportDataPoint[], category: ProductCategory | null, timePeriod: number): ReportDataPoint[] => {
  if (!category) {
    return []
  }
  const grouped = data.reduce<Record<string, ReportDataPoint>>((out, curr) => {
    if (curr.productCategoryName !== category.name) {
      return out
    }
    if (!out[curr.timestamp]) {
      out[curr.timestamp] = {
        timestamp: curr.timestamp
      }
    }

    const group = out[curr.timestamp];
    const productKey = curr.productName;
    if (!group[productKey]) {
      group[productKey] = '0'
    }
    group[productKey] = new Decimal(group[productKey] ?? 0).plus(new Decimal(curr.totalProducts ?? 0)).toFixed(0);

    return out
  }, {})
  return Object.values(grouped).map(m => ({ ...m, timestamp: formatDateForReport(parseCustomDate(m.timestamp), timePeriod) }))
}

export default function ProductsSoldReport() {
  const { productCategories = [] } = useProductCategories()
  const { date, setDate, timePeriod, setTimePeriod, view, handleViewChange, comparisonPeriods, setComparisonPeriods, clear } = useReportHeader()

  const [selectedCategory, setSelectedCategory] = useState<ProductCategory | null>(null);
  const { data, loading: isLoading } = useRequest(() => reportsApi.productSales(date, timePeriod, comparisonPeriods), [date, timePeriod, comparisonPeriods]);

  const handleProductCategoryChange = (value: string) => {
    const category = productCategories.find(f => f.id === +value) ?? null
    setSelectedCategory(category)
  }

  const grouped = selectedCategory ? groupByCategoryAndProduct(data ?? [], selectedCategory, timePeriod) : groupByCategory(data ?? [], timePeriod)
  const reportData = padDataPoints(grouped, timePeriod, date, comparisonPeriods)

  const barKeys = (
    selectedCategory
      ? grouped.flatMap((datapoint) => Object.keys(datapoint)
        .filter(f => f !== 'timestamp' && f.indexOf('_units') === -1))
        .filter((el, i, r) => r.indexOf(el) === i)
      : productCategories.map(m => m.name)
  ).sort((a, b) => a.localeCompare(b))

  const totals = getTotalsRow(grouped, barKeys, 0)

  const selectedCategoryId = selectedCategory?.id || 0

  return (
    <>
      <ReportHeader
        date={date}
        setDate={setDate}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
        comparisonPeriods={comparisonPeriods}
        setComparisonPeriods={setComparisonPeriods}
        view={view}
        handleViewChange={handleViewChange}
        clear={() => {
          clear();
          setSelectedCategory(null)
        }}
      >
        <TextField
          select
          size="small"
          value={selectedCategoryId}
          label="Category"
          sx={{ minWidth: 130 }}
          onChange={(e) => handleProductCategoryChange(e.target.value)}
        >
          <MenuItem value={0}>All</MenuItem>
          {productCategories.sort((a, b) => a.name.localeCompare(b.name)).map((category) => (
            <MenuItem
              key={category.id}
              value={category.id}
            >
              {category.name}
            </MenuItem>
          ))}
        </TextField>
      </ReportHeader>
      <LoadingSpinner isLoading={isLoading}>
        <>
          {view === ReportView.Chart ? (
            <TpgeStackedBarChart
              data={reportData}
              barKeys={barKeys}
              timePeriod={timePeriod}
              customToolTip={() => (
                <Tooltip
                  formatter={(value) => new Decimal(value.toString()).toFixed(0)}
                  labelFormatter={(label) => barLabel(label, timePeriod)}
                />
              )}
            />
          ) : (
            <StyledTableContainer>
              <Table size="medium" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Date/Time</TableCell>
                    {
                      barKeys.map(key => <TableCell key={key} align="right">{key}</TableCell>)
                    }
                  </TableRow>
                </TableHead>
                <TableBody>

                  {
                    grouped.map(datapoint => (
                      <TableRow key={datapoint.timestamp}>
                        <TableCell component="th" scope="row">
                          {datapoint.timestamp}
                        </TableCell>
                        {
                          barKeys.map(key => <TableCell key={key} align="right">{datapoint?.[key]}</TableCell>)
                        }
                      </TableRow>
                    ))
                  }
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Total
                    </TableCell>
                    {
                      barKeys.map(key => <TableCell key={key} align="right">{totals[key]}</TableCell>)
                    }
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableContainer>
          )}
        </>
      </LoadingSpinner>
    </>
  );
}
