import { OrderAdapterType } from './enums';
import {
  OrderItem,
  RegularCalculableItem,
  StaffCalculableItem,
  StaffOrderItem,
} from './types';

export function adaptOrderItem(item: OrderItem): RegularCalculableItem {
  return {
    type: OrderAdapterType.Regular,
    productSizeId: item.productSizeId,
    sizeOptions: item.sizeOptions,
    selectedVariants: item.selectedVariants,
  };
}

export function adaptStaffOrderItem(item: StaffOrderItem): StaffCalculableItem {
  return {
    type: OrderAdapterType.Staff,
    productSizeId: item.productSizeId,
    basePrice: item.basePrice,
    selectedVariants: item.selectedVariants,
  };
}
