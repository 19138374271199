/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { add } from 'date-fns/add';
import { isBefore } from 'date-fns/isBefore';
import isEqual from 'lodash/isEqual';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ProductCategoryType } from '../../../../common/enums';
import { sortOrdersById } from '../../../../common/sortFunctions';
import { StaffOrder } from '../../../../common/types';
import InfoCard from '../../../../components/card/InfoCard';
import useAlert from '../../../../hooks/useAlert';
import usePlayAlarmSound from '../../../../hooks/usePlayAlarmSound';
import {
  getOrderPosition,
  handleFinishOrder,
  handleStartStopOrder,
  shiftOrderItems,
} from '../../functions/orderFunctions';
import {
  allFoodPending,
  allFoodStarted,
  anyFoodPending
} from '../../functions/stationFunctions';
import DraggableFoodOrderCard from './DraggableFoodOrderCard';
import StationHeader from './StationHeader';

interface FoodStationProps {
  orders: StaffOrder[];
}

const hasPendingFood = (orders: StaffOrder[]): boolean => {
  const nowPlusFiveMinutes = add(new Date(), { minutes: 5 });
  let shouldRingAlarm = false;

  for (const order of orders) {
    if (shouldRingAlarm) {
      break;
    }
    if (anyFoodPending(order.orderItems)) {
      // Check if the requested pickup time is within 5 minutes of the current time or in the past
      if (order.requestedPickupTime) {
        const pickupTime = new Date(order.requestedPickupTime);
        shouldRingAlarm = isBefore(pickupTime, nowPlusFiveMinutes)
      }
    }
  }
  return shouldRingAlarm;
}

export default function FoodStation({ orders, children }: PropsWithChildren<FoodStationProps>) {
  usePlayAlarmSound(hasPendingFood(orders || []), 3000);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { showAlert } = useAlert();
  const [draggableOrders, setDraggableOrders] = useState<StaffOrder[]>([
    ...orders,
  ]);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  })
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  })
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  })
  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    keyboardSensor,
  );

  function updateDraggableOrders(orders: StaffOrder[]) {
    const incomingOrdersAreDifferent = !isEqual(
      sortOrdersById(orders),
      sortOrdersById(draggableOrders),
    );
    const incomingOrders = [...orders];
    if (incomingOrdersAreDifferent) {
      incomingOrders.forEach((incomingOrder) => {
        const draggableOrder = draggableOrders.find(
          (dragOrder) => dragOrder.id === incomingOrder.id,
        );
        incomingOrder.orderItems.forEach((incomingOrderItem) => {
          const foundDraggableOrderItem = draggableOrder?.orderItems.find(
            (dragOrderItem) => dragOrderItem.id === incomingOrderItem.id,
          );
          if (foundDraggableOrderItem) {
            incomingOrderItem.queueOrder = foundDraggableOrderItem.queueOrder;
          }
        });
      });
      setDraggableOrders([...orders]);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    updateDraggableOrders(orders);
  }, [orders]);

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id === over.id) return;

    setDraggableOrders((prevOrders) => {
      const originalPosition = getOrderPosition(active.id, draggableOrders);
      const newPosition = getOrderPosition(over.id, draggableOrders);
      const newArray = arrayMove(prevOrders, originalPosition, newPosition);
      shiftOrderItems(newArray, [ProductCategoryType.Food], showAlert);
      return newArray;
    });
  }

  const sortedFilteredOrders = draggableOrders.map((order) => {
    return {
      id: order.id,
      order,
      isAllFoodPlaced: allFoodPending(order.orderItems),
      isAllFoodStarted: allFoodStarted(order.orderItems),
    };
  });

  return (
    <Grid
      size={12}
      sx={{
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StationHeader />
      <Divider />
      {children}
      {sortedFilteredOrders.length === 0 ? (
        <InfoCard
          Icon={NotificationsIcon}
          title="No Results Found"
          subtitle="Try refreshing your page or check back later."
        />
      ) : (
        <Box
          sx={{
            overflowY: 'auto',
            height: 'calc(100vh - 64px)',
          }}
        >
          <Grid container spacing={1} sx={{ padding: 3 }}>
            <DndContext
              collisionDetection={closestCorners}
              sensors={sensors}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext
                items={draggableOrders}
                strategy={verticalListSortingStrategy}
              >
                {sortedFilteredOrders.map(
                  (
                    {
                      order,
                      isAllFoodPlaced,
                      isAllFoodStarted,
                    }
                  ) => (
                    <Grid size={12} key={order.id}>
                      <Paper
                        elevation={8}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          padding: 0,
                          margin: 0,
                          height: '100%',
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.common.deepblue,
                        }}
                      >
                        <DraggableFoodOrderCard
                          order={order}
                          isAllFoodPlaced={isAllFoodPlaced}
                          isAllFoodStarted={isAllFoodStarted}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          handleStartOrder={() => {
                            handleStartStopOrder(
                              order,
                              true,
                              [ProductCategoryType.Food],
                              showAlert,
                              setIsLoading,
                            );
                          }}
                          handleStopOrder={() => {
                            handleStartStopOrder(
                              order,
                              false,
                              [ProductCategoryType.Food],
                              showAlert,
                              setIsLoading,
                            );
                          }}
                          handleFinishOrder={() =>
                            handleFinishOrder(
                              order,
                              [ProductCategoryType.Food],
                              showAlert,
                              setIsLoading,
                            )
                          }
                        />
                      </Paper>
                    </Grid>
                  ),
                )}
              </SortableContext>
            </DndContext>
          </Grid>
        </Box>
      )}
    </Grid>
  );
}
